<div id="home">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
        <div class="side-button">
            <button  mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div class="ecgtitle">ISHNE <span style="color:#D20000;">ECG</span> UNIVERSITY V2</div>
        <span class="menu-spacer"></span>
        <div class="top-menu">
            <!-- The following menu items will be hidden on both SM and XS screen sizes -->
            <a href="#introduction" mat-button>Introduction</a>
            <a href="#exam" mat-button>Certification</a>
            <a href="#faculty" mat-button>Faculty</a>
            <a href="#contactsection" mat-button>Contact</a>
            <a href="#loginregistercontent" mat-button><span [hidden]="this.firebaseService.registeredUser()">Register / Login</span><span [hidden]="!this.firebaseService.registeredUser()"><i [matTooltipClass]="'my-tooltip'" matTooltip="{{this.firebaseService.User.Name}}" class="fas fa-user fa-lg"></i></span></a>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    
    <mat-sidenav-container >
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a (click)="sidenav.toggle()" href="#introduction" mat-list-item>Introduction</a>
                <a (click)="sidenav.toggle()" href="#exam" mat-list-item>Certification</a>
                <a (click)="sidenav.toggle()" href="#faculty" mat-list-item>Faculty</a>
                <a (click)="sidenav.toggle()" href="#contactsection" mat-list-item>Contact</a>
                <a (click)="sidenav.toggle()" href="#loginregistercontent" mat-list-item>Register / Login</a>
            </mat-nav-list>
        </mat-sidenav>
        
        <mat-sidenav-content>
        
            <!-- banner-area -->
            <div class="banner-area">
                <div class="row">
                    <div class="vcenter">
                        <div class="banner_text">
                            <div class="element-slide-line1">Free access to</div>
                            <div class="element-slide-line2 white-shadow">How to interpret ECGs in patients<br>with implantable devices</div>
                            <div class="element-slide-line3 col-sm-12 white-shadow">Review the most clinically relevant ECG patterns,<br> in a simple and effective interactive way.</div>
                            <div class="element-slide-line4 col-sm-12">
                                <a href="#loginregistercontent"><button mat-raised-button (click)="selectLoginRegister(true);" color="accent" id="btnregister" class="btn show-register-form" style="margin-right: 20px;">Sign me up!</button></a>
                                <a href="#loginregistercontent"><button mat-raised-button (click)="selectLoginRegister(false);" color="accent" id="btnlogin" class="btn show-login-form">Log in!</button></a>
                                </div>
                        </div>
                    </div>
                    <div class="bottom_logos d-none d-lg-flex">
                        <div class="middleside"></div>
                        <div class="aside aside-1"><a target="_blank" href="https://www.cardiovascular.abbott/us/en/hcp/products/electrophysiology/ensite-precision-cardiac-mapping-system.html"><img src="../../assets/images/logo_abbott.png" alt="Abbott"></a></div>
                        <div class="aside aside-2"><a target="_blank" href="https://ishne.org"><img src="../../assets/images/ishne_thb.png" alt="ISHNE"></a></div>
                    </div>
                </div>
            </div>

            <!-- middle-content -->
            <div class="middle-content">
            <div class="container">

            <!-- evaluate link -->
            <div class="row">
                <div class="certificate-banner">
                    <div class="div-center">
                        <a href="#exam">
                            <div class="post-author__avatar">
                                <img alt="Get certificate" src="../../assets/images/men_writen2.jpg" style="height: 80px;">
                            </div>
                            <div style="float: left;">
                                <h3 class="" style="color: white;">Evaluate your knowledge</h3>
                                <div class="" style="color: #42c5ea;margin-bottom: 10px;">Answer questions about V2 cases and obtain a certificate</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <!--div class="row">
                <div class="condolences-banner">
                    <div class="div-center">
                        <div class="post-author__avatar">
                            <img alt="Get certificate" src="../../assets/images/lazo-negro-luto.png" style="height: 70px;margin-bottom: 10px;">
                        </div>
                        <div style="font-style: oblique; font-family: Times">The Directors of ECG University send  their deepest condolences to family, colleagues, fellows and friends of <span style="font-weight: 600;">Prof Hein Wellens</span>. He will be deeply missed by the ECG world
                        </div>
                    </div>
                </div>
            </div-->

            <!-- middle-content -->
            <a id="loginregistercontent" name="loginregistercontent"></a>
            <div class="row-space">

                <!-- Register / Login -->
                <div class="col-sm-10 col-md-9 col-lg-8 col-centered">
                    <div class="form-box loginregisterbox">
                        
                        <!-- form-top -->
                        <div class="form-top">
                            <div class="form-top-left">
                                <h3 [hidden]="!this.firebaseService.registeredUser()">Welcome</h3>
                                <h3 [hidden]="this.firebaseService.registeredUser()">
                                    <span class="label-regiter show-register-form" [ngClass]="{'active':showRegister}" (click)="showRegister=true;">Register</span> 
                                    <span class="label-divider">/</span> 
                                    <span class="label-login show-login-form" [ngClass]="{'active':!showRegister}" (click)="showRegister=false;">Login</span>
                                </h3>
                            </div>
                            <div class="form-top-right">
                                <i class="fa fa-lock fa-3x"></i>
                            </div>
                        </div>
                        
                        <!-- form-bottom -->
                        <div class="form-bottom">
                            <div class="">
                                <!-- Loged in content -->
                                <div class="" [hidden]="!this.firebaseService.registeredUser()">
                                    <p class="show-welcome">Hello {{this.firebaseService.User.Name}},</p> 
                                    <p>You are welcome to start studying this course</p>
                                    <p>Enjoy this exciting educational experience!!!</p>
                                    <p></p>
                                    <div class="home-join-button text-center">
                                        <a href="/v2/cases" color="accent" class="btn btn-yellow btn-lg">
                                            <img src="../../assets/images/paemaker.png"><h3>Implantable Devices</h3><span>Roster of Educational Cases v2</span>
                                        </a>
                                    </div>
                                    <br>
                                    <div class="home-join-button text-center">
                                        <a href="/v1/cases" color="accent" class="btn btn-primary"><i class="fa fa-heartbeat fa-4x"></i><h3 style="color: white;">Basic ECG Diagnosis</h3><span>Roster of Educational Cases v1</span></a>
                                    </div>
                                    <div style="padding-top: 20px;" >
                                        <button mat-button (click)="Logout()"><i class="fa fa-sign-out fa-fw"></i> Logout </button>
                                    </div>
                                </div>

                                <!-- register-form -->
                                <div class="register-form" [hidden]="this.firebaseService.registeredUser()" [ngStyle]="{'display': showRegister===true ? 'inherit' : 'none' }">
                                    <form class="r-form" ngNativeValidate (ngSubmit)="signMeUp(RegName.value,RegEmail.value,RegProfStatus.value,RegMedDoctor.value,RegInstitution.value,RegFind.value)">
                                        <div class="form-container">
                                            <div class="form-group">
                                                <i class="fas fa-user fa-lg"></i>
                                                <input type="text" placeholder="Name" name="reg-name" id="reg-name" class="form-control" #RegName ngModel required />
                                            </div>
                                            <div class="form-group">
                                                <i class="fas fa-envelope fa-lg"></i>
                                                <input type="email" placeholder="Email" name="reg-email" id="reg-email" class="form-control" #RegEmail ngModel required />
                                            </div>
                                            <div class="form-group">
                                                <i class="fas fa-medkit fa-lg"></i>
                                                <select class="form-control" name="reg-prof-status" id="reg-prof-status" #RegProfStatus ngModel='Profesional Status'>
                                                    <option *ngFor="let prof of OptionsProfStatus" [selected]="prof == OptionsProfStatus[0]" [disabled]="prof == OptionsProfStatus[0]">{{prof}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group" [hidden]="RegProfStatus.value != 'Medical Doctor'">
                                                <i class="fas fa-user-md fa-lg"></i>
                                                <select class="form-control" name="reg-medical-doctor" id="reg-medical-doctor" #RegMedDoctor ngModel="Specialty">
                                                    <option *ngFor="let prof of OptionsSpecialties" [selected]="prof == OptionsSpecialties[0]" [disabled]="prof == OptionsSpecialties[0]">{{prof}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <i class="fas fa-clinic-medical fa-lg"></i>
                                                <select class="form-control" name="reg-institution" id="reg-institution" #RegInstitution ngModel="Institution">
                                                    <option *ngFor="let prof of OptionsInstitutions" [selected]="prof == OptionsInstitutions[0]" [disabled]="prof == OptionsInstitutions[0]">{{prof}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <i class="fas fa-bullhorn fa-lg"></i>
                                                <select class="form-control" name="reg-find" id="reg-find" #RegFind ngModel="How did you find out our course">
                                                    <option *ngFor="let prof of OptionsHowFind" [selected]="prof == OptionsHowFind[0]" [disabled]="prof == OptionsHowFind[0]">{{prof}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <button mat-raised-button color="accent" type="submit" id="btnregister" class="btn-lg">Sign me up!</button>
                                        </div>
                                    
                                    </form>
                                </div>
                                
                                <!-- login-form -->
                                <div class="login-form" [hidden]="this.firebaseService.registeredUser()" [ngStyle]="{'display': showRegister===true ? 'none' : 'inherit' }">
                                <form class="l-form" ngNativeValidate (ngSubmit)="signIn(SignInEmail.value)">
                                    <div class="form-group">
                                        <i class="fas fa-envelope fa-lg"></i>
                                        <input type="text" name="login-email" placeholder="Email" class="form-control" id="login-email" #SignInEmail ngModel required />
                                    </div>
                                    <div class="text-right">
                                        <button mat-raised-button color="accent" type="submit" id="btnlogin" class="btn-large" >Sign in!</button>
                                    </div>
                                </form>
                                <!--div class="social-login">
                                    <p style="font-size:140%">Or login with:</p>
                                    <div class="social-login-buttons">
                                    <a class="btn btn-link-1" href="#"><i class="fa fa-facebook"></i></a>
                                    <a class="btn btn-link-1" href="#"><i class="fa fa-twitter"></i></a>
                                    <a class="btn btn-link-1" href="#"><i class="fa fa-google-plus"></i></a>
                                    </div>
                                </div-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Visits Counter -->
                <div class="col-sm-10 col-md-9 col-lg-8 col-centered">
                    <div class="form-box loginregisterbox">
                        <div class="form-top">
                            <div class="text-center" style="padding-top: 10px;">
                                <h4>visits: {{this.firebaseService.visits.home}}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Introduction -->
                <div class="tablet" style="margin-top: 20px;" id="introduction">
                    <div class="row">
                        <div class="form-box col-md-4">
                            <div class="form-top">
                            <div class="form-top-left">
                                <h5>Jonathan Steinberg</h5>
                            </div>
                            <div class="form-top-right">
                                <i class="fa fa-video-camera fa-2x"></i>
                            </div>
                            </div>
                            <div class="form-bottom">
                            <div class="forms-movie-panel">
                                <div style="padding:133.33% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/414973382?loop=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;max-height:360" frameborder="0" allow="autoplay;fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                            </div>
                        </div>

                        <div class="form-box col-md-4">
                            <div class="form-top">
                            <div class="form-top-left">
                                <h5>Sergio Dubner</h5>
                            </div>
                            <div class="form-top-right">
                                <i class="fa fa-video-camera fa-2x"></i>
                            </div>
                            </div>
                            <div class="form-bottom">
                            <div class="forms-movie-panel">
                                <div style="padding:133.33% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/414972466?loop=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;max-height:360" frameborder="0" allow="autoplay;fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                            </div>
                        </div>

                        <div class="form-box col-md-4">
                            <div class="form-top">
                            <div class="form-top-left">
                                <h5>Adrian Baranchuk</h5>
                            </div>
                            <div class="form-top-right">
                                <i class="fa fa-video-camera fa-2x"></i>
                            </div>
                            </div>
                            <div class="form-bottom">
                            <div class="forms-movie-panel">
                                <div style="padding:133.33% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/414969095?loop=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay;fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            </div>
            </div>

            <!-- Services -->
            <section class="section-content-left-icon">
                <div class="container">
                    <div class="row">
                    <div class="col s12 m6">
                        <div class="left-icon-wraper">
                        <div class="icon"><i class="fa fa-graduation-cap fa-lg"></i></div>
                        <div class="content">
                            <h2>Accurate Interpretation</h2>
                            <p>Provide basic ECG interpretation skills to broad spectrum of clinicians.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col s12     m6">
                        <div class="left-icon-wraper">
                        <div class="icon"><i class="fas fa-gift fa-lg"></i></div>
                        <div class="content">
                            <h2>Free</h2>
                            <p>This course is free of charge.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col s12 m6">
                        <div class="left-icon-wraper">
                        <div class="icon"><i class="fas fa-book-reader fa-lg"></i></div>
                        <div class="content">
                            <h2>Target Audience</h2>
                            <p>Applicable to physicians in multiple specialties and health professionals in fields that necessitate working knowledge of ECG interpretation.</p>
                        </div>
                        </div>
                    </div>
                
                    <div class="col s12 m6">
                        <div class="left-icon-wraper">
                        <div class="icon"><i class="fas fa-heartbeat fa-lg"></i></div>
                            <div class="content">
                            <h2>ECG Cases</h2>
                            <p>Gain new skills and knowledge based on <b>real</b> 12-lead ECG cases, with teaching material from world renowned electrocardiographers.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
          
            <!-- EXAM -->
            <section class="single-page-bar" style="padding-bottom: 0px;" id="exam">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h2 class="title" style="color: yellow; font-weight: 400;padding-bottom: 20px;">ISHNE Certificate</h2>
                            <div class="row">
                                <div class="col-xs-12 col-sm-8 vcenter" style="text-align: left; color: white;">
                                    <h4 style="font-weight: 400;">
                                        <p>ISHNE offers a course certificate.</p>
                                        <p>You can then work through the course materials and complete assessment items. Earn a certificate by gaining the required number of marks for the course.</p>
                                        <p>Press the follow button and get your certificate:</p>
                                    </h4>
                                    <!--div class="text-center button-start-exam" style="margin-top: 20px;">
                                        <button [disabled]="true" mat-raised-button color="accent">It will be available at the end of the course.</button>
                                    </div-->
                                    <div class="text-center button-start-exam" style="margin-top: 20px;" matTooltip="For registered users only" matTooltipClass="example-tooltip-red" [matTooltipDisabled]="!this.firebaseService.examButton[this.firebaseService.examButtonState()].tooltip">
                                        <button [disabled]="!this.firebaseService.examButton[this.firebaseService.examButtonState()].enabled" mat-raised-button color="accent" (click)="startExam()">{{this.firebaseService.examButton[this.firebaseService.examButtonState()].text}}</button>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4 text-center"><img src="../../assets/images/ishnegescerts.png" alt="ISHNE certificate"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <app-clinicians-gallery></app-clinicians-gallery>
      
            <!-- ISHNE -->
            <section class="single-page-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h3 class="title">Sponsoring Professional Organization</h3>
                            <a href="http://ishne.org"><h2 class="white-shadow">ISHNE</h2>
                            <h3 class="white-shadow">International Society for Holter and Noninvasive Electrocardiology</h3>
                            <img src="assets/images/ishne_thb.png" alt="ISHNE logo"></a>
                        </div>
                    </div>
                </div>
            </section>
            
            <!-- Contact -->
            <section class="contact-section" id="contactsection">
            <div class="container">
                <div class="contact-title text-center">
                <h3>If you have any questions or comments, please feel free to contact us</h3>
                </div>
            
                <div class="contact-form">
                <form ngNativeValidate (ngSubmit)="sendMessage(ContactName.value, ContactEmail.value, ContactSubject.value, ContactMessage.value);  ContactName.value=''; ContactSubject.value=''; ContactMessage.value=''">
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="form-group">
                                <label for="cname" class="sr-only">Name</label>
                                <input type="text" class="form-control" required id="cname" placeholder="Name"  #ContactName ngModel [ngModelOptions]="{standalone: true}" />
                            </div>
                            
                            <div class="form-group">
                                <label for="csubject" class="sr-only">Subject</label>
                                <input type="text" class="form-control" required id="csubject" placeholder="Subject" #ContactSubject ngModel [ngModelOptions]="{standalone: true}" />
                            </div>
                
                            <div class="form-group">
                                <label for="cemail" class="sr-only">Email</label>
                                <input type="email" class="form-control" required id="cemail" placeholder="Email Address" #ContactEmail [(ngModel)]="this.firebaseService.User.email" [ngModelOptions]="{standalone: true}"  />
                            </div>
                        </div>
                
                        <div class="col-sm-12 col-md-8">
                            <div class="form-group">
                                <label for="cmessage" class="sr-only">Message</label>
                                <textarea class="form-control" required rows="7" id="cmessage" placeholder="Message" #ContactMessage ngModel [ngModelOptions]="{standalone: true}"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 text-right">
                            <button mat-raised-button color="primary" type="submit" class="btn-large" >Send Message</button>
                        </div>
                    </div>
                </form>
                </div> <!-- contact-form -->
            </div>
            </section> <!-- contact-section -->
            
            <!-- footer -->
            <app-footer></app-footer>
            
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<social-buttons></social-buttons>