import { Component, OnInit, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() public title: string;
  @Input() public subtitle: string;

  constructor(private titleService: Title, private meta: Meta, private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle( "ECG University - " + this.title );
    this.meta.updateTag({ property: 'og:title', content: this.title })
    this.meta.updateTag({ property: 'og:url', content: this.router.url })
  }

}
