import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { ArrayType } from '@angular/compiler';
import { AngularFireStorage } from '@angular/fire/storage';
//import * as firebase from 'firebase';
import { firestore } from 'firebase/app';
//import { HttpClient, HttpParams } from '@angular/common/http';
//import { observable } from 'rxjs';
import { UUID } from 'angular2-uuid';


@Injectable({
  providedIn: 'root'
})

export class FirebaseService implements OnInit {

  seconds: number = 0;
  timer: any;
  questionProgress: number = 0;
  correctAnswerCount: number = 0;
  questionCount: number = 0;
  questions = [];
  casesV2: any;
  User: any = {
    Name: "",
    email: "",
    ExamDownloadedSent: false,
    ExamPassed: false,
    ExamID: '',
    ExamLastTest: 0,
    Subscribed: false,
    Unsubscribed: false
    /*Exam: {
      Passed: false,
      LastTest: new Date(0),
      DownloadedSent: false
    }*/
  }
  examPeriod: number = 2; // minutes   examDate.setMinutes ( examDate.getMinutes() + examPeriod );
  visits: any = {
    home: 0,
    case1: 0,
    case2: 0
  };

  examButton = [
    {enabled: false, tooltip: true, text: "Start the exam", url: ""},
    {enabled: true,  tooltip: false,  text: "Start the exam", url: "window.location.href = 'exam';"},
    {enabled: true,  tooltip: false, text: "Download the exam", url: "this.firebaseService.downloadCertificate()"},
    {enabled: false, tooltip: false, text: "Passed ! Wait for certificate", url: ""},
    {enabled: false, tooltip: false, text: "Read the cases and try again", url: ""}
  ]
  

  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) { //, private http: HttpClient
    this.firestore.collection('Analytics').doc('v2').valueChanges().subscribe(
      ref => {
        if (ref != undefined) {
          this.visits.home = +ref['home'] + 53582;
          this.visits.case1 = +ref['case v1'] + 53582;
          this.visits.case2 = +ref['case v2'] + ref['home'];
        }
      }
    );

    let user = localStorage.getItem('User');
    if (user != null) {
      this.User = JSON.parse(user);
    }
  } 

  ngOnInit() {
    
  }
  
  // searchHeroes(term: string): observable<Hero[]>{

  //   const params = new HttpParams({fromString: 'name=term'});
  //     return this.http.request('GET', '', {responseType:'json', params});
  //  }
   
  // public getVisitis() {
    
  //   this.http.post('https://analyticsreporting.googleapis.com/v4/reports:batchGet',
  //     {
  //       "reportRequests":[
  //       {
  //         "viewId":"207190610",
  //         "dateRanges":[
  //         {
  //           "startDate":"2020-04-01",
  //           "endDate":"2020-05-21"
  //         }],
  //         "metrics":[
  //         {
  //           "expression":"ga:users"
  //         }]
  //       }]
  //     }
  //   ).subscribe(
  //     (ref: any) => 
  //     this.visits = ref.rows
  //   );
  // }
  //---------------- User/Exam Methods ----------------
  public setUser(name: string, email: string) {
    this.User.email = email;
    //if (this.User.ExamPassed == undefined) this.User.ExamPassed = false;
    //if (this.User.ExamLastTest == undefined) this.User.ExamLastTest = new Date(0);
    //if (this.User.ExamDownloadedSent == undefined) this.User.ExamDownloadedSent = false;
    localStorage.setItem('User', JSON.stringify(this.User));
  }

  public registeredUser() {
    return this.User.email != '';
  }

  public examButtonState() {
    let state = 0;
    if (this.User.email != '') {
      // Si no hizo el examen
      if (this.User.ExamLastTest == undefined) state = 1
      // Si lo hizo
      else {
        if (this.User.ExamPassed) {
          // Si aprobó el examen
          if (this.User.ExamDownloadedSent) {
            // Si ya esta listo el certificado
            state = 2;
            this.examButton[2].url = "";
          } else {
            // Si no esta listo
            state = 3;
          }
        } else {
          // Si desaprobó
          //if ((new Date(this.User.ExamLastTest).getTime() + this.examPeriod*60000) < (new Date()).getTime() ) {
          // in miliseconds (60000 ms = 1 min)
          if ((this.User.ExamLastTest + this.examPeriod*300000) < (new Date()).getTime() ) {
            // Pasó el tiempo de espero
            state = 1;
          } else {
            // No pasó el tiempo
            state = 4;
          }
        }
      }
    }
    return state;
  }

  public downloadCertificate() {
    // Create a reference with an initial file path and name
    var storageRef = this.storage.ref("certificates/certificate_" + this.User.ExamID + ".pdf" );
    storageRef.getDownloadURL().subscribe(function(url) {
      //console.log("Descargando archivo " + url);
      window.open(url)
    });
    
    /*var fileRef = this.storage.ref('certificates/709Cepeda.pdf');
    fileRef.getDownloadURL().subscribe(function(url) {

      console.log("THIS LINE NEVER GETS PRINTED");
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
          //blob = xhr.response;  
      };
      xhr.open('GET', url);
      xhr.send();

    });*/
  }
  //---------------- Quizz Methods ----------------

  public updateUserExam() {
    let date = new Date(this.User.ExamLastTest);
    localStorage.setItem('User', JSON.stringify(this.User));
    var data = {
      ExamLastTest: date,
      ExamPassed: this.User.ExamPassed
    }
    this.firestore.collection('Users').doc(this.User.email).update(data);

    if (this.User.ExamPassed) {
      this.firestore.collection('ExamPassed').doc(date.toISOString()).set({ date: date, email: this.User.email });
      var cert_data = {
        date: date,
        email: this.User.email,
        name: this.User.Name
      }
      this.firestore.collection('Certificates').doc(UUID.UUID()).set( cert_data );
    }
  }

  public displayTimeElapsed() {

    var hours = Math.floor(this.seconds / 3600);
    var minutes = Math.floor((this.seconds-hours*3600) / 60).toString(); minutes = (minutes.length < 2) ? "0" + minutes : minutes;
    var seconds = Math.floor(this.seconds % 60).toString(); seconds = (seconds.length < 2) ? "0" + seconds : seconds;

    return hours.toString() + ':' + minutes + ':' + seconds;
  }

  public getQuestions() {
    return this.firestore.collection('Question').valueChanges();
  }

  public getAnswers(id: string) {
    return this.firestore.collection('Question').doc(id).valueChanges()["Answer"];
  }

  //---------------- ANALYTICS ----------------
  public logVisit(eventName: string) {
    const increment = firestore.FieldValue.increment(1);
    let dic = {};
    dic[eventName] = increment;
    this.firestore.collection('Analytics').doc('v2').update(dic);
  }


  //---------------- HELPERS ----------------
  public copyDoc(name: string) {

    this.firestore.collection('CasesGroups').doc(name).valueChanges().subscribe(
      res => {
        if (res != undefined) {
          this.firestore.collection('versions').doc('v1').collection('case_groups').doc(name).set(res);
        }
      }
    );
  }

  public copyColection() {

    this.firestore.collection('CasesGroups').get().subscribe(
      res => {
        if (res != undefined) {
          res.docs.forEach(
            doc => {
              this.copyDoc(doc.id);
              //this.firestore.collection('versions').doc('v1').collection('cases').doc(doc.id).set(doc.data);
          });
          
        }
      }
    );
  }

  //---------------- USERS ----------------

  public AddExistingUser(name: string, email: string, prof_status: string, speciality: string, institution: string, find: string,
    date: Date, subscribed: boolean, unsubscribed: boolean) {
    var data = {
      Name: name,
      ProfStatus: prof_status,
      Speciality: speciality,
      Institution: institution,
      Find: find,
      register_date: date,
      Subscribed: subscribed,
      Unsubscribed: unsubscribed
    }
    return this.firestore.collection('Users').doc(email).set(data);
  }

  public SignUpUser(name: string, email: string, prof_status: string, speciality: string, institution: string, find: string) {
    var data = {
      Name: name,
      ProfStatus: prof_status,
      Speciality: speciality,
      Institution: institution,
      Find: find,
      register_date: (new Date),
      Subscribed: false,
      Unsubscribed: false
    }
    this.firestore.collection('Users').doc(email).set(data);
    return this.firestore.collection('Users').doc(email).get();
  }

  public GetUser(email: string) {
    return this.firestore.collection('Users').doc(email).get();
  }

  // public GetUserValue(email: string) {
  //   return this.firestore.collection('Users').doc(email).valueChanges();
  // }

  public Login(email: string, ref: any) {
    localStorage.clear();
    this.User = ref;
    this.User.email = email;
    this.User.ExamLastTest = this.User.ExamLastTest == undefined ? this.User.ExamLastTest = 0 : this.User.ExamLastTest.seconds * 1000;
    localStorage.setItem('User', JSON.stringify(this.User));
    this.logVisit('login');
    // Update last login date
    let data = { LastLoginDate: new Date(), }
    this.firestore.collection('Users').doc(this.User.email).update(data);
    // If not subscribed then add to list
    if (this.User.Subscribed == false) {
      this.firestore.collection('NotSubscribed').doc(email).set({ date: new Date()});
    }
  }

  //---------------- CERTIFICATE ----------------

  public GetCertificate(uuid: string) {
    return this.firestore.collection('Certificates').doc(uuid).get();
  }

  public GetExamPassedUsers() {
    this.firestore.collection('Users', ref => ref.where('ExamPassed', '==', true)).valueChanges({idField: 'id'})
    .subscribe(
      ref => {
        console.log('Users that pass the exam', ref);
      }
    )
  }

  //---------------- SUBSCRIPTION ----------------

  public Subscribe(email: string) {
    this.firestore.collection('Users').doc(this.User.email).update({ Subscribed: true, Unsubscribed: false, });
    this.firestore.collection('Subscriptions').doc(email).set({});
    this.firestore.collection('Unsubscribed').doc(email).delete();
  }

  public Unsubscribe(email: string) {
    this.firestore.collection('Users').doc(this.User.email).update({ Unsubscribed: true, });
    this.firestore.collection('Unsubscribed').doc(email).set({});
    this.firestore.collection('Subscriptions').doc(email).delete();
  }

  //---------------- CONTACT FORM ----------------

  public saveMessage(name: string, email: string, subject: string, message: string) {
    
    const currentTime = new Date();
    var data = {
      name: name,
      email: email,
      subject: subject,
			message: message,
    }
		this.firestore.collection('ContactFormMessages').doc(currentTime.toISOString()).set(data);
  }

  
  //---------------- CASES ----------------
  
 /* public getCase(name: string) {
    return this.firestore.collection('Cases').doc(name).valueChanges();
  }

  public getCaseV2(version: string, name: string) {
    return this.casesV2 = this.firestore.collection('versions').doc(version).collection('cases').doc(name).valueChanges();
  }*/

  /*public getCaseGroups() {
    return this.firestore.collection('CasesGroups', ref => ref.orderBy('order')).get();
  }*/

  // public getCasesV2(version: string) {
  //   return this.firestore.collection('versions').doc(version).collection('case_list', ref => ref.orderBy('num')).valueChanges();
  // }

  /*public getCaseList(version: string) {
    return this.firestore.collection('versions').doc(version).collection('case_list').valueChanges();
  }*/

  //---------------- AUTHORS ----------------

  private shuffle(array: any) {
    let currentIndex = array.length, temporaryValue: any, randomIndex: any;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  public getAuthors() {
    let authors = [
      {
        "name": "Adrian Baranchuk, MD",
        "avatar": "assets/authors/baranchuk.jpg",
        "country": "assets/flags/canada.gif",
        "link": "http://deptmed.queensu.ca/faculty/adrian_baranchuk",
        "institution": "Queen's University",
      }, {
        "name": "Takanori Ikeda, MD",
        "avatar": "assets/authors/takanori.jpg",
        "country": "assets/flags/japan.gif",
        "link": "https://www.iscpcardio.org/governor/takanori-ikeda/",
        "institution": "Toho University Faculty of Medicine",
      }, {
        "name": "Bradley P. Knight, MD",
        "avatar": "assets/authors/knight.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://www.feinberg.northwestern.edu/faculty-profiles/az/profile.html?xid=18543",
        "institution": "Northwestern University",
      }, {
        "name": "Jessica Charron, APN",
        "avatar": "assets/authors/medical-doctor.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "Northwestern University",
      }, {
        "name": "Jonathan S. Steinberg, MD",
        "avatar": "assets/authors/steinberg.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://www.radcliffecardiology.com/authors/jonathan-s-steinberg",
        "institution": "University of Rochester School of Medicine & Dentistry",
      }, {
        "name": "Oswaldo Gutierrez, MD",
        "avatar": "assets/authors/gutierrez.jpg",
        "country": "assets/flags/costa_rica.gif",
        "link": "http://www.clinicacardiovascularcr.com/oswaldo_gutierrez.php",
        "institution": "Clínica de Diagnóstico Cardiovascular",
      }, {
        "name": "Bruno Toscani, MD",
        "avatar": "assets/authors/toscani.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "McGill University Health Centre",
      }, {
        "name": "Vidal Essebag, MD",
        "avatar": "assets/authors/essebag.jpg",
        "country": "assets/flags/canada.gif",
        "link": "https://www.mcgill.ca/expmed/dr-vidal-essebag",
        "institution": "McGill University",
      }, {
        "name": "Sergio J. Dubner, MD",
        "avatar": "assets/authors/dubner2.jpg",
        "country": "assets/flags/argentina.gif",
        "link": "",
        "institution": "Clinica y Maternidad Suizo Argentina",
      }, {
        "name": "Noah Williford, MD",
        "avatar": "assets/authors/williford.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "The University of Iowa Hospitals & Clinics",
      }, {
        "name": "Michael V. Orlov",
        "avatar": "assets/authors/orlov.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "St. Elizabeth's Medical Center of Boston",
      }, {
        "name": "Timothy McIntyre, MS",
        "avatar": "assets/authors/mcintyre2.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "Boston Scientific",
      }, {
        "name": "Brian Olshansky, MD",
        "avatar": "assets/authors/olshansky.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "Covenant Medical Center-Waterloo",
      }, {
        "name": "Konstantinos P. Letsas, MD",
        "avatar": "assets/authors/letsas.jpg",
        "country": "assets/flags/greece.gif",
        "link": "",
        "institution": "Evangelismos General Hospital of Athens",
      }, {
        "name": "George Bazoukis, MD",
        "avatar": "assets/authors/bazoukis.jpg",
        "country": "assets/flags/greece.gif",
        "link": "",
        "institution": "Medical school of Ioannina",
      }, {
        "name": "Jose Manuel Rubio, MD",
        "avatar": "assets/authors/rubio.jpg",
        "country": "assets/flags/spain.gif",
        "link": "https://www.fjd.es/es/cuadro-medico/jose-manuel-rubio-campal",
        "institution": "Universidad Autonoma de Madrid",
      }, {
        "name": "Göksel Çinier, MD",
        "avatar": "assets/authors/goksel.jpg",
        "country": "assets/flags/turkey.gif",
        "link": "",
        "institution": "Kackar State Hospital, Rize, Turkey",
      }, {
        "name": "Maude Peretz-Larochelle, MD",
        "avatar": "assets/authors/peretz.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "University of Ottawa Heart Institute, Canada",
      }, {
        "name": "Mouhannad Sadek, MD",
        "avatar": "assets/authors/sadek.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "University of Ottawa Heart Institute, Canada",
      }, {
        "name": "Martin Green, MD",
        "avatar": "assets/authors/martingreen2.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "University of Ottawa Heart Institute, Canada",
      }, {
        "name": "Panagiotis Korantzopoulos, MD",
        "avatar": "assets/authors/korantzopoulos.jpg",
        "country": "assets/flags/greece.gif",
        "link": "",
        "institution": "University of Ioannina (Greece)",
      }, {
        "name": "Christopher Pekrul, MD",
        "avatar": "assets/authors/pekrul.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "Libin Cardiovascular Institute, University of Calgary",
      }, {
        "name": "D. Johnson, MD",
        "avatar": "assets/authors/medical-doctor.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "Libin Cardiovascular Institute, University of Calgary",
      }, {
        "name": "George D. Veenhuyzen, MD",
        "avatar": "assets/authors/veenhuyzen.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "Libin Cardiovascular Institute, University of Calgary",
      }, {
        "name": "Nora Goldschlager, MD",
        "avatar": "assets/authors/goldschlager.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "San Francisco General Hospital, University of California",
      }, {
        "name": "Horacio Quiroga Ponce, MD",
        "avatar": "assets/authors/quiroga.jpg",
        "country": "assets/flags/argentina.gif",
        "link": "",
        "institution": "Hospital Aeronáutico Central, Argentina",
      }
    ];
    return this.shuffle(authors);
  }

 /* public getAuthorsV1() {
    let authors = [
      {
        "name": "Melvin M. Scheinman",
        "avatar": "assets/authors/scheinman.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://www.ucsfhealth.org/melvin.scheinman",
        "institution": "University of California",
        "bib": "Professor of Medicine EP section University of California SF  US<br>Former President of the North American society of Pacing and Electrophysiology<br>Former Northern California governor for the ACC<br>Chief of the Comprehensive Genetic Arrhythmia Program at UCSF<br>Former associate editor for EP for Circulation and JACC<br>Associate editor PACE"
      }, {
        "name": "Nitish Badhwar, MD",
        "avatar": "assets/authors/badhwar.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://med.stanford.edu/profiles/nitish-badhwar",
        "institution": "Stanford University School of Medicine",
        "bib": "Nitish Badhwar, MD is Professor of Medicine and Director of Cardiac Electrophysiology Training Program at Stanford University School of Medicine. Dr. Badhwar received his medical degree from Maulana Azad Medical College"
      }, {
        "name": "Sergio Dubner, MD",
        "avatar": "assets/authors/dubner.jpg",
        "country": "assets/flags/argentina.gif",
        "institution": "Clinica y Maternidad Suizo Argentina",
        "bib": "Chief Electrophysiologic Service, Clinica y Maternidad Suizo Argentina and De los Arcos Sanatorio<br>Former President International Society for Holter and Noninvasive Electrocardiology (ISHNE)<br>Past Editor-in-Chief Cardiology Journal<br>Editorial Member Journal of the American College of Cardiology (JACC) Spanish version."
      }, {
        "name": "Peter H. Stone, MD",
        "avatar": "assets/authors/stone.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "http://researchfaculty.brighamandwomens.org/briprofile.aspx?id=2477",
        "institution": "Harvard Medical School",
        "bib": "Professor of Medicine, Harvard Medical School, Boston, MA , USA<br>Senior Physician, Brigham & Women\'s Hospital, Boston, MA, USA<br>Director, Vascular Profiling Research Group, Brigham & Women\'s Hospital, Boston, MA, USA<br>Past President, International Society for Holter and Noninvasive Electrocardiology (ISHNE)<br>Editor, Ischemic Heart Disease section, Current Opinion in Cardiology<br>Editorial Board, Journal of the American College of Cardiology"
      }, {
        "name": "Pavel Antiperovitch, MD",
        "avatar": "assets/authors/antiperovitch.jpg",
        "country": "assets/flags/canada.gif",
        "link": "",
        "institution": "University of Western Ontario",
        "bib": "Cardiology Fellow<br>University of Western Ontario, London, Canada<br>Junior Editor, Journal of Electrocardiology<br>Software Developer - Electronic Medication Education Tools<br>"
      }, {
        "name": "William F. McIntyre, MD",
        "avatar": "assets/authors/mcintyre.jpg",
        "country": "assets/flags/canada.gif",
        "link": "https://www.researchgate.net/profile/William_Mcintyre",
        "institution": "Population Health Research Institute",
        "bib": "Clinical and Research Fellow<br>Population Health Research Institute, McMaster University, Hamilton, Ontario, Canada<br>Member-in-training representative, Canadian Heart Rhythm Society<br>Section Editor, Residents and Fellows Corner, Journal of Electrocardiology<br>Editorial Board Member, Canadian Journal of Cardiology"
      }, {
        "name": "Matt Needleman, MD",
        "avatar": "assets/authors/needleman.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://health.usnews.com/doctors/matthew-needleman-859365",
        "institution": "Walter Reed National Military Medical Center",
        "bib": "Commander, Medical Corps, U.S. Navy<br>Adult, Pediatric, and Adult Congenital Electrophysiology<br>Director, Electrophysiology Laboratory, WRNNMC<br>Associate Program Director, Cardiovascular Disease Fellowship, WRNNMC<br>Chair, Credentials Committee, WRNNMC<br>Associate Professor of Medicine and Pediatrics, USUHS",
      }, {
        "name": "Jonathan S. Steinberg, MD",
        "avatar": "assets/authors/steinberg.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "",
        "institution": "University of Rochester School of Medicine & Dentistry",
        "bib": "Professor of Medicine (adj)<br>University of Rochester School of Medicine & Dentistry, Rochester, NY, USA<br>Core Professor of Cardiology<br>Seton Hall-Hackensack Meridian School of Medicine, Nutley, NJ, USA<br>Immediate Past President, International Society of Holter and Noninvasive Electrocardiology<br>"
      }, {
        "name": "Niraj Varma, MD",
        "avatar": "assets/authors/varma.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://health.usnews.com/doctors/niraj-varma-797314",
        "institution": "Cleveland Clinic",
        "bib": "Consultant Electrophysiologist<br>Cleveland Clinic, Cleveland, Ohio, USA<br>President, International Society of Holter and Noninvasive Electrocardiology"
      }, {
        "name": "Adrian Baranchuk, MD",
        "avatar": "assets/authors/baranchuk.jpg",
        "country": "assets/flags/canada.gif",
        "link": "http://deptmed.queensu.ca/faculty/adrian_baranchuk",
        "institution": "Queen\'s University",
        "bib": "Professor of Medicine<br>Queen\'s University, Kingston, Ontario, Canada<br>President, International Society of Electrocardiology<br>Vice President  Interamerican Society of Cardiology<br>Editor-in-Chief, Journal of Electrocardiology"
      }, {
        "name": "Pyotr Platonov, MD",
        "avatar": "assets/authors/platonov.jpg",
        "country": "assets/flags/sweden.gif",
        "link": "https://www.med.lu.se/english/klinvetlund/cardiology/research/project_leaders/platonov",
        "institution": "Lund University",
        "bib": "Professor of Cardiology<br>Lund University, Lund, Sweden<br>Member of the International Council of Electrocardiology<br>Board of Trustees, International Society for Holter and Noninvasive Electrocardiology<br>Board of Directors, Computing in Cardiology<br>Associate Editor, Annals of Noninvasive Electrocardiology<br>Clinical Electrocardiology Section Editor, Journal of Electrocardiology<br>"
      }, {
        "name": "Philippe Chevalier, MD",
        "avatar": "assets/authors/chevalier.jpg",
        "country": "assets/flags/france.gif",
        "institution": "Université Claude Bernard Lyon",
        "bib": "Professor of Cardiology  Université Claude Bernard Lyon, Lyon, France<br>Coordinator of the National reference center of inherited arrhythmias of Lyon<br>Head of the department of Arrhythmias, Hospices Civils de Lyon, France<br>Co-directeur of the research Heart Unit, INMG, Lyon, France<br>Vice-President Université Claude Bernard Lyon1, Lyon, France<br>Member :  French Cardiology Society, e-cardiology group of the ESC<br>Associate Editor, Europace"
      }, {
        "name": "Antonio Bayes de Luna, MD",
        "avatar": "assets/authors/bayes.jpg",
        "country": "assets/flags/spain.gif",
        "link": "https://www.quironsalud.es/hospital-barcelona/es/cuadro-medico/antonio-bayes-luna",
        "institution": "Institut Català Ciències Cardiovasculars",
        "bib": "Institut Català Ciències Cardiovasculars<br>Former President of the World Heart Federation<br>Emeritus Professor of Cardiology Autonomous University Barcelona<br>Honorary Director Cardiac Department St. Pau Hospital, Barcelona<br>Senior Investigator Instituto Catalan Ciencias Cardiovasculares, St. Pau Hospital, Barcelona"
      }, {
        "name": "Miquel Fiol Sala, MD",
        "avatar": "assets/authors/fiol.jpg",
        "country": "assets/flags/spain.gif",
        "link": "http://www.clinicarotger.com/es/dr-miguel-fiol-sala.html",
        "institution": "Illes Balears Health Research Institute",
        "bib": "Scientific Director. Illes Balears Health Research Institute, Palma. Mallorca, Spain<br>Council Member, International Society for Holter and Noninvasive Electrocardiology<br>Council Member, International Society of Electrocardiology<br>Editorial Board, Journal of Electrocardiology"
      }, {
        "name": "Martin Green, MD",
        "avatar": "assets/authors/martingreen.jpg",
        "country": "assets/flags/canada.gif",
        "link": "https://www.ottawaheart.ca/physician-researcher-profile/green-martin",
        "institution": "University of Ottawa",
        "bib": "Professor of Medicine (Cardiology)<br>University of Ottawa, Ottawa, Ontario, Canada"
      }, {
        "name": "Takanori Ikeda, MD",
        "avatar": "assets/authors/takanori.jpg",
        "country": "assets/flags/japan.gif",
        "institution": "Toho University Faculty of Medicine",
        "bib": "Professor of Medicine<br>Toho University Faculty of Medicine, Tokyo, Japan<br>Vice President, International Society for Holter and Noninvasive Electrocardiology<br>Council Member, International Society of Electrocardiology<br>Trustee, Japanese Circulation Society<br>Trustee, Japanese Heart Rhythm Society<br>Associate Editor, Some International and Domestic Academic Journals"
      }, {
        "name": "Emanuela T. Locati, MD",
        "avatar": "assets/authors/locati.jpg",
        "country": "assets/flags/italy.gif",
        "link": "http://www.ospedaleniguarda.it/professionisti-e-aziende/i-nostri-medici/profilo/locati-teresina",
        "institution": "Niguarda Hospital",
        "bib": "Consultant in Cardiology and Arrhythmology, and Responsible for Clinical Research, Cardiovascular Department, Niguarda Hospital, Milan, Italy<br>Director, Studio Cardiologico Locati, Milan, Italy<br>Chairman, Noninvasive Electrophysiology Task-Force, and Regional Council Member, Italian Society of  Arrhythmology and Cardiac Stimulation (AIAC)<br>Governor, International Society of Holter and Noninvasive Electrophysiology (ISHNE)<br>Council Member, International Society of Electrocardiology (ISE)<br>Member, European Society of Cardiology (ESC), European Heart Rhythm Association (EHRA) and WG on e-Cardiology"
      }, {
        "name": "Giuseppe Bagliani, MD",
        "avatar": "assets/authors/bagliani.jpg",
        "country": "assets/flags/italy.gif",
        "link": "",
        "institution": "Ospedale di Foligno",
        "bib": ""
      }, {
        "name": "Arthur J. Moss, MD",
        "avatar": "assets/authors/moss.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "https://www.urmc.rochester.edu/people/20392826-arthur-j-moss",
        "institution": "University of Rochester Medical Center",
        "bib": "Brad Berk Distinguished Professor of Medicine (Cardiology)<br>Founding Director, Heart Research Follow-up Program<br>University of Rochester Medical Center"
      }, {
        "name": "Christien Ka Hou Li, MD",
        "avatar": "assets/authors/li.jpg",
        "country": "assets/flags/hong_kong.gif",
        "link": "https://www.researchgate.net/profile/Christien_Li",
        "institution": "Newcastle Medical School",
        "bib": "Newcastle Medical School (UK)<br>Clinical Researcher<br>Department of Medicine and Therapeutics (CUHK)"
      }, {
        "name": "Gary Tse, BA",
        "avatar": "assets/authors/tse.jpg",
        "country": "assets/flags/hong_kong.gif",
        "link": "https://www.lihs.cuhk.edu.hk/en-us/research/investigators/tse,gary.aspx",
        "institution": "Chinese University of Hong Kong",
        "bib": "Assistant Professor, Faculty of Medicine<br>Principal Investigator, Li Ka Shing Institute of Health Sciences<br>Chinese University of Hong Kong, Hong Kong"
      }, {
        "name": "Jose Gant Lopez, MD",
        "avatar": "assets/authors/lopez.jpg",
        "country": "assets/flags/argentina.gif",
        "link": "",
        "institution": "Germany Hospital",
        "bib": "Chief of Cardiac Electrophysiology Service. Germany Hospital.<br>Profesor of Cardiac Electrophysiology. Del Salvador University.<br>Council of Electrophysiology. Argentinian  Society of Cardiology.<br>Argentinian Society of Cardiac Electrophysiology. Member.<br>Argentinian  Society of Cardiology. Titular member.<br>Buenos Aires. Argentina."
      }, {
        "name": "Kjell Nikus, MD",
        "avatar": "assets/authors/nikus.jpg",
        "country": "assets/flags/finland.gif",
        "link": "https://www.researchgate.net/profile/Kjell_Nikus2",
        "institution": "Faculty of Medicine and Life Sciences",
        "bib": "Internist, Cardiologist<br>Professor in Cardiology, Faculty of Medicine and Life Sciences, University of Tampere<br>Chief Specialist, Cardiology Department, Heart Center, Tampere University Hospital, Finland<br>Work and scientific profile: ECG, Acute coronary syndrome, Interventional cardiology"
      }, {
        "name": "Andres Perez-Riera, MD",
        "avatar": "assets/authors/riera.jpg",
        "country": "assets/flags/brazil.gif",
        "link": "https://www.researchgate.net/profile/Andres_Perez-Riera",
        "institution": "Faculdade de Medicina do ABC",
        "bib": "Medical doctor<br>Master degree in Health Science<br>PhD in Health Science<br>Post graduate advisor in the ABC School of Medicine<br>Member of the Expert Committee of the Second Consensus Conference Endorsed by the Heart Rhythm Society and the European Heart Rhythm Association about Brugada syndrome."
      }, {
        "name": "Ljuba Bacharova, MD",
        "avatar": "assets/authors/bacharova.jpg",
        "country": "assets/flags/slovakia.gif",
        "institution": "International Laser Center",
        "bib": "Doctor of Medicine, Doctor of Sciences<br>International Laser Center, Bratislava, Slovak Republic, Faculty of Medicine, Comenius University, Bratislava, Slovak Republic<br>Former President, International Society of Electrocardiology<br>Former member of the Board of Directors, International Society of Computerized Electrocardiology<br>Executive Editor, Journal of Electrocardiology<br>Organizer of the International Research Interdisciplinary Schools"
      }, {
        "name": "Konstantinos P. Letsas, MD",
        "avatar": "assets/authors/letsas.jpg",
        "country": "assets/flags/greece.gif",
        "link": "https://www.researchgate.net/profile/Konstantinos_Letsas",
        "institution": "Evangelismos General Hospital of Athens",
        "bib": "Consultant Cardiologist-Electrophysiologist<br>Evangelismos General Hospital of Athens, Athens, Greece<br>Member of the EHRA certification examination committee for Invasive Cardiac Electrophysiology<br>Former EHRA Young EP ambassador for Greece<br>Editorial board member in International Journals"
      }, {
        "name": "Prof. Marek Jastrzebski, MD",
        "avatar": "assets/authors/marek.jpg",
        "country": "assets/flags/poland.gif",
        "institution": "Jagiellonian University",
        "bib": "Professor of Medicine<br>Jagiellonian University, Krakow, Poland<br>Section Editor, Heart Beat Journal"
      }, {
        "name": "Larisa Tereshchenko, MD",
        "avatar": "assets/authors/larisa.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "http://www.ohsu.edu/xd/health/services/heart-vascular/research-training/research-programs/tereshchenko.cfm",
        "institution": "Oregon Health & Science University",
        "bib": "Associate Professor of Medicine<br>Oregon Health & Science University, Portland, Oregon, USA<br>Treasurer, International Society for Computerized Electrocardiology<br>Editorial Board Member: Circulation Arrhythmia and Electrophysiology, Heart Rhythm, Annals of Noninvasive Electrocardiology<br>Section Editor: Journal of Electrocardiology"
      }, {
        "name": "Mohammad Shenasa, MD",
        "avatar": "assets/authors/shenasa.jpg",
        "country": "assets/flags/united_states_of_america.gif",
        "link": "http://www.shenasa.net/m-shenasa-md-facc.html",
        "institution": "O’Connor Hospital",
        "bib": "Professor Emeritus, University of Pittsburgh<br>O’Connor Hospital, Heart & Rhythm Medical Group, San Jose, CA<br>Chairperson of Quality Control, O’Connor Hospital<br>Chairman of Cardiac Mapping Workshop, 1992, 2018<br>Associate editor, PACE Reviews<br>Editorial board, PACE<br>Editorial board, Journal of Electrocardiology<br>"
      }
    ];
    return authors;
  }
*/

 /* public getCasesV1() {
    let cases = [
      {
        "num" : "1",
        "name" : "Bradyarrhythmias",
        "cases": [
          {
            "num": "1",
            "authors": ["Melvin M. Scheinman, MD", "Nitish Badhwar, MD"],
            "href": "scheinman2",
            "avatar": ["assets/authors/scheinman.jpg", "assets/authors/badhwar.jpg"],
            "country": ["assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif"],
            "author_link": ["https://www.ucsfhealth.org/melvin.scheinman","http://profiles.ucsf.edu/nitish.badhwar"],
            "bib": ["Professor of Medicine EP section University of California SF  US<br>Former President of the North American society of Pacing and Electrophysiology<br>Former Northern California governor for the ACC<br>Chief of the Comprehensive Genetic Arrhythmia Program at UCSF<br>Former associate editor for EP for Circulation and JACC<br>Associate editor PACE", 
                    ""]
          }, {
            "num": "2",
            "authors": ["Sergio Dubner, MD"],
            "href": "dubner",
            "avatar": ["assets/authors/dubner.jpg"],
            "country": ["assets/flags/argentina.gif"],
            "bib": ["Chief Electrophysiologic Service, Clinica y Maternidad Suizo Argentina and De los Arcos Sanatorio<br>Former President International Society for Holter and Noninvasive Electrocardiology (ISHNE)<br>Past Editor-in-Chief Cardiology Journal<br>Editorial Member Journal of the American College of Cardiology (JACC) Spanish version."]
          }, {
            "num": "3",
            "authors": ["Peter H. Stone, MD"],
            "href": "stone",
            "avatar": ["assets/authors/stone.jpg"],
            "country": ["assets/flags/united_states_of_america.gif"],
            "author_link": ["http://researchfaculty.brighamandwomens.org/briprofile.aspx?id=2477"],
            "bib": ["Professor of Medicine, Harvard Medical School, Boston, MA , USA<br>Senior Physician, Brigham & Women\'s Hospital, Boston, MA, USA<br>Director, Vascular Profiling Research Group, Brigham & Women\'s Hospital, Boston, MA, USA<br>Past President, International Society for Holter and Noninvasive Electrocardiology (ISHNE)<br>Editor, Ischemic Heart Disease section, Current Opinion in Cardiology<br>Editorial Board, Journal of the American College of Cardiology"]
          }, {
            "num": "4",
            "authors": ["Pavel Antiperovitch, MD", "William F. McIntyre, MD"],
            "href": "mcintyre",
            "avatar": ["assets/authors/antiperovitch.jpg", "assets/authors/mcintyre.jpg"],
            "country": ["assets/flags/canada.gif", "assets/flags/canada.gif"],
            "author_link": ["","https://www.researchgate.net/profile/William_Mcintyre"],
            "bib": ["Cardiology Fellow<br>University of Western Ontario, London, Canada<br>Junior Editor, Journal of Electrocardiology<br>Software Developer - Electronic Medication Education Tools<br>", 
                    "Clinical and Research Fellow<br>Population Health Research Institute, McMaster University, Hamilton, Ontario, Canada<br>Member-in-training representative, Canadian Heart Rhythm Society<br>Section Editor, Residents and Fellows Corner, Journal of Electrocardiology<br>Editorial Board Member, Canadian Journal of Cardiology"]
          }
        ]
      }, {
        "num" : "2",
        "name" : "Tachyarrhythmias",
        "cases": [
          {
            "num": "5",
            "authors": ["Matt Needleman, MD", "Jonathan S. Steinberg, MD"],
            "href": "needleman",
            "avatar": ["assets/authors/needleman.jpg", "assets/authors/steinberg.jpg"],
            "country": ["assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif"],
            "author_link": ["https://health.usnews.com/doctors/matthew-needleman-859365",""],
            "bib": ["Commander, Medical Corps, U.S. Navy<br>Adult, Pediatric, and Adult Congenital Electrophysiology<br>Director, Electrophysiology Laboratory, WRNNMC<br>Associate Program Director, Cardiovascular Disease Fellowship, WRNNMC<br>Chair, Credentials Committee, WRNNMC<br>Associate Professor of Medicine and Pediatrics, USUHS", 
                "Professor of Medicine (adj)<br>University of Rochester School of Medicine & Dentistry, Rochester, NY, USA<br>Core Professor of Cardiology<br>Seton Hall-Hackensack Meridian School of Medicine, Nutley, NJ, USA<br>Immediate Past President, International Society of Holter and Noninvasive Electrocardiology<br>"]
          }, {
            "num": "6",
            "authors": ["Niraj Varma, MD"],
            "href": "varma",
            "avatar": ["assets/authors/varma.jpg"],
            "country": ["assets/flags/united_states_of_america.gif"],
            "author_link": ["https://health.usnews.com/doctors/niraj-varma-797314"],
            "bib": ["Consultant Electrophysiologist<br>Cleveland Clinic, Cleveland, Ohio, USA<br>President, International Society of Holter and Noninvasive Electrocardiology"]
          }, {
            "num": "7",
            "authors": ["Adrian Baranchuk, MD"],
            "href": "baranchuk",
            "avatar": ["assets/authors/baranchuk.jpg"],
            "country": ["assets/flags/canada.gif"],
            "author_link": ["http://deptmed.queensu.ca/faculty/adrian_baranchuk"],
            "bib": ["Professor of Medicine<br>Queen\'s University, Kingston, Ontario, Canada<br>President, International Society of Electrocardiology<br>Vice President  Interamerican Society of Cardiology<br>Editor-in-Chief, Journal of Electrocardiology"]
          }, {
            "num": "8",
            "authors": ["Pyotr Platonov, MD"],
            "href": "platonov",
            "avatar": ["assets/authors/platonov.jpg"],
            "country": ["assets/flags/sweden.gif"],
            "author_link": ["https://www.med.lu.se/english/klinvetlund/cardiology/research/project_leaders/platonov"],
            "bib": ["Professor of Cardiology<br>Lund University, Lund, Sweden<br>Member of the International Council of Electrocardiology<br>Board of Trustees, International Society for Holter and Noninvasive Electrocardiology<br>Board of Directors, Computing in Cardiology<br>Associate Editor, Annals of Noninvasive Electrocardiology<br>Clinical Electrocardiology Section Editor, Journal of Electrocardiology<br>"]
          }, {
            "num": "9",
            "authors": ["Philippe Chevalier, MD"],
            "href": "chevalier",
            "avatar": ["assets/authors/chevalier.jpg"],
            "country": ["assets/flags/france.gif"],
            "bib": ["Professor of Cardiology  Université Claude Bernard Lyon1, Lyon, France<br>Coordinator of the National reference center of inherited arrhythmias of Lyon<br>Head of the department of Arrhythmias, Hospices Civils de Lyon, France<br>Co-directeur of the research Heart Unit, INMG, Lyon, France<br>Vice-President Université Claude Bernard Lyon1, Lyon, France<br>Member :  French Cardiology Society, e-cardiology group of the ESC<br>Associate Editor, Europace"]
          }
        ]
      }, {
        "num" : "3",
        "name" : "Acute MI & ischemia",
        "cases": [
          {
            "num": "10",
            "authors": ["Antonio Bayes de Luna, MD"],
            "href": "bayes",
            "avatar": ["assets/authors/bayes.jpg"],
            "country": ["assets/flags/spain.gif"],
            "author_link": ["https://www.quironsalud.es/hospital-barcelona/es/cuadro-medico/antonio-bayes-luna"],
            "bib": ["Institut Català Ciències Cardiovasculars<br>Former President of the World Heart Federation<br>Emeritus Professor of Cardiology Autonomous University Barcelona<br>Honorary Director Cardiac Department St. Pau Hospital, Barcelona<br>Senior Investigator Instituto Catalan Ciencias Cardiovasculares, St. Pau Hospital, Barcelona"]
          }, {
            "num": "11",
            "authors": ["Miquel Fiol Sala, MD"],
            "href": "fiol",
            "avatar": ["assets/authors/fiol.jpg"],
            "country": ["assets/flags/spain.gif"],
            "author_link": ["http://www.clinicarotger.com/es/dr-miguel-fiol-sala.html"],
            "bib": ["Scientific Director. Illes Balears Health Research Institute, Palma. Mallorca, Spain<br>Council Member, International Society for Holter and Noninvasive Electrocardiology<br>Council Member, International Society of Electrocardiology<br>Editorial Board, Journal of Electrocardiology"]
          }
        ]
      }, {
        "num" : "4",
        "name" : "Conduction disturbances",
        "cases": [
          {
            "num": "12",
            "authors": ["Martin Green, MD"],
            "href": "martingreen",
            "avatar": ["assets/authors/martingreen.jpg"],
            "country": ["assets/flags/canada.gif"],
            "author_link": ["https://www.ottawaheart.ca/physician-researcher-profile/green-martin"],
            "bib": ["Professor of Medicine (Cardiology)<br>University of Ottawa, Ottawa, Ontario, Canada"]
          }, {
            "num": "13",
            "authors": ["Takanori Ikeda, MD"],
            "href": "ikeda",
            "avatar": ["assets/authors/takanori.jpg"],
            "country": ["assets/flags/japan.gif"],
            "bib": ["Professor of Medicine<br>Toho University Faculty of Medicine, Tokyo, Japan<br>Vice President, International Society for Holter and Noninvasive Electrocardiology<br>Council Member, International Society of Electrocardiology<br>Trustee, Japanese Circulation Society<br>Trustee, Japanese Heart Rhythm Society<br>Associate Editor, Some International and Domestic Academic Journals"]
          }, {
            "num": "14",
            "authors": ["Emanuela T. Locati, MD", "Giuseppe Bagliani, MD"],
            "href": "locati",
            "avatar": ["assets/authors/locati.jpg", "assets/authors/bagliani.jpg"],
            "country": ["assets/flags/italy.gif", "assets/flags/italy.gif"],
            "author_link": ["http://www.ospedaleniguarda.it/professionisti-e-aziende/i-nostri-medici/profilo/locati-teresina",""],
            "bib": ["Consultant in Cardiology and Arrhythmology, and Responsible for Clinical Research, Cardiovascular Department, Niguarda Hospital, Milan, Italy<br>Director, Studio Cardiologico Locati, Milan, Italy<br>Chairman, Noninvasive Electrophysiology Task-Force, and Regional Council Member, Italian Society of  Arrhythmology and Cardiac Stimulation (AIAC)<br>Governor, International Society of Holter and Noninvasive Electrophysiology (ISHNE)<br>Council Member, International Society of Electrocardiology (ISE)<br>Member, European Society of Cardiology (ESC), European Heart Rhythm Association (EHRA) and WG on e-Cardiology", 
                ""]
          }, {
            "num": "15",
            "authors": ["Melvin M. Scheinman, MD", "Nitish Badhwar, MD"],
            "href": "scheinman1",
            "avatar": ["assets/authors/scheinman.jpg", "assets/authors/badhwar.jpg"],
            "country": ["assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif"],
            "author_link": ["https://www.ucsfhealth.org/melvin.scheinman","http://profiles.ucsf.edu/nitish.badhwar"],
            "bib": ["Professor of Medicine EP section University of California SF  US<br>Former President of the North American society of Pacing and Electrophysiology<br>Former Northern California governor for the ACC<br>Chief of the Comprehensive Genetic Arrhythmia Program at UCSF<br>Former associate editor for EP for Circulation and JACC<br>Associate editor PACE", 
                ""]
          }
        ]
      }, {
        "num" : "5",
        "name" : "Inherited disorders",
        "cases": [
          {
            "num": "16",
            "authors": ["Arthur J. Moss, MD"],
            "href": "moss",
            "avatar": ["assets/authors/moss.jpg"],
            "country": ["assets/flags/united_states_of_america.gif"],
            "author_link": ["https://www.urmc.rochester.edu/people/20392826-arthur-j-moss"],
            "bib": ["Brad Berk Distinguished Professor of Medicine (Cardiology)<br>Founding Director, Heart Research Follow-up Program<br>University of Rochester Medical Center"]
          }, {
            "num": "17",
            "authors": ["Christien Ka Hou Li, MD", "Gary Tse, BA"],
            "href": "tse",
            "avatar": ["assets/authors/li.jpg", "assets/authors/tse.jpg"],
            "country": ["assets/flags/hong_kong.gif", "assets/flags/hong_kong.gif"],
            "author_link": ["https://www.researchgate.net/profile/Christien_Li","https://www.lihs.cuhk.edu.hk/en-us/research/investigators/tse,gary.aspx"],
            "bib": ["Newcastle Medical School (UK)<br>Clinical Researcher<br>Department of Medicine and Therapeutics (CUHK)",
            "Assistant Professor, Faculty of Medicine<br>Principal Investigator, Li Ka Shing Institute of Health Sciences<br>Chinese University of Hong Kong, Hong Kong"]
          }, {
            "num": "18",
            "authors": ["Jose Gant Lopez, MD"],
            "href": "lopez",
            "avatar": ["assets/authors/lopez.jpg"],
            "country": ["assets/flags/argentina.gif"],
            "author_link": [""],
            "bib": ["Chief of Cardiac Electrophysiology Service. Germany Hospital.<br>Profesor of Cardiac Electrophysiology. Del Salvador University.<br>Council of Electrophysiology. Argentinian  Society of Cardiology.<br>Argentinian Society of Cardiac Electrophysiology. Member.<br>Argentinian  Society of Cardiology. Titular member.<br>Buenos Aires. Argentina."]
          }
        ]
      }, {
        "num" : "6",
        "name" : "Chamber enlargement",
        "cases": [
          {
            "num": "19",
            "authors": ["Kjell Nikus, MD", "Andres Perez-Riera, MD"],
            "href": "nikus",
            "avatar": ["assets/authors/nikus.jpg", "assets/authors/riera.jpg"],
            "country": ["assets/flags/finland.gif", "assets/flags/brazil.gif"],
            "author_link": ["https://www.researchgate.net/profile/Kjell_Nikus2","https://www.researchgate.net/profile/Andres_Perez-Riera"],
            "bib": ["Internist, Cardiologist<br>Professor in Cardiology, Faculty of Medicine and Life Sciences, University of Tampere<br>Chief Specialist, Cardiology Department, Heart Center, Tampere University Hospital, Finland<br>Work and scientific profile: ECG, Acute coronary syndrome, Interventional cardiology",
            "Medical doctor<br>Master degree in Health Science<br>PhD in Health Science<br>Post graduate advisor in the ABC School of Medicine<br>Member of the Expert Committee of the Second Consensus Conference Endorsed by the Heart Rhythm Society and the European Heart Rhythm Association about Brugada syndrome."]
          }, {
            "num": "20",
            "authors": ["Ljuba Bacharova, MD"],
            "href": "bacharova",
            "avatar": ["assets/authors/bacharova.jpg"],
            "country": ["assets/flags/slovakia.gif"],
            "bib": ["Doctor of Medicine, Doctor of Sciences<br>International Laser Center, Bratislava, Slovak Republic, Faculty of Medicine, Comenius University, Bratislava, Slovak Republic<br>Former President, International Society of Electrocardiology<br>Former member of the Board of Directors, International Society of Computerized Electrocardiology<br>Executive Editor, Journal of Electrocardiology<br>Organizer of the International Research Interdisciplinary Schools"]
          }, {
            "num": "21",
            "authors": ["Konstantinos P. Letsas, MD"],
            "href": "letsas",
            "avatar": ["assets/authors/letsas.jpg"],
            "country": ["assets/flags/greece.gif"],
            "author_link": ["https://www.researchgate.net/profile/Konstantinos_Letsas"],
            "bib": ["Consultant Cardiologist-Electrophysiologist<br>Evangelismos General Hospital of Athens, Athens, Greece<br>Member of the EHRA certification examination committee for Invasive Cardiac Electrophysiology<br>Former EHRA Young EP ambassador for Greece<br>Editorial board member in International Journals"]
          }
        ]
      }, {
        "num" : "7",
        "name" : "Paced rhythms",
        "cases": [
          {
            "num": "22",
            "authors": ["Melvin M. Scheinman, MD", "Nitish Badhwar, MD"],
            "href": "scheinman3",
            "avatar": ["assets/authors/scheinman.jpg", "assets/authors/badhwar.jpg"],
            "country": ["assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif"],
            "author_link": ["https://www.ucsfhealth.org/melvin.scheinman","http://profiles.ucsf.edu/nitish.badhwar"],
            "bib": ["Professor of Medicine EP section University of California SF  US<br>Former President of the North American society of Pacing and Electrophysiology<br>Former Northern California governor for the ACC<br>Chief of the Comprehensive Genetic Arrhythmia Program at UCSF<br>Former associate editor for EP for Circulation and JACC<br>Associate editor PACE",
            ""]
          }, {
            "num": "23",
            "authors": ["Prof. Marek Jastrzebski, MD"],
            "href": "marek",
            "avatar": ["assets/authors/marek.jpg"],
            "country": ["assets/flags/poland.gif"],
            "bib": ["Professor of Medicine<br>Jagiellonian University, Krakow, Poland<br>Section Editor, Heart Beat Journal"]
          }
        ]
      }, {
        "num" : "8",
        "name" : "Miscellaneous",
        "cases": [
          {
            "num": "24",
          "authors": ["Larisa Tereshchenko, MD"],
          "href": "larisa",
          "avatar": ["assets/authors/larisa.jpg"],
          "country": ["assets/flags/united_states_of_america.gif"],
          "author_link": ["http://www.ohsu.edu/xd/health/services/heart-vascular/research-training/research-programs/tereshchenko.cfm"],
          "bib": ["Associate Professor of Medicine<br>Oregon Health & Science University, Portland, Oregon, USA<br>Treasurer, International Society for Computerized Electrocardiology<br>Editorial Board Member: Circulation Arrhythmia and Electrophysiology, Heart Rhythm, Annals of Noninvasive Electrocardiology<br>Section Editor: Journal of Electrocardiology"]
        }, {
          "num": "25",
          "authors": ["Mohammad Shenasa, MD"],
          "href": "shenasa",
          "avatar": ["assets/authors/shenasa.jpg"],
          "country": ["assets/flags/united_states_of_america.gif"],
          "author_link": ["http://www.shenasa.net/m-shenasa-md-facc.html"],
          "bib": ["Professor Emeritus, University of Pittsburgh<br>O’Connor Hospital, Heart & Rhythm Medical Group, San Jose, CA<br>Chairperson of Quality Control, O’Connor Hospital<br>Chairman of Cardiac Mapping Workshop, 1992, 2018<br>Associate editor, PACE Reviews<br>Editorial board, PACE<br>Editorial board, Journal of Electrocardiology<br>"]
        }
        ]
      }
    ];
    return cases;        
  }
    */
}
