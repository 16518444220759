<app-header [title]="'Result'" [subtitle]=""></app-header>

<div class="result" [ngStyle]="{'background-image': 'url(' + resultBackground + ')'}">
  <div class="container">

    <div class="row">
      <div class="center text-center" style="width: 100%;padding-top: 40px;">
        <div class="white-shadow">

          <h1 class="header" style="color:rgba(21, 122, 160, 0.96);">{{this.resultMessage}}</h1>
          <h4 class="card-title">Student: {{this.firebaseService.User.name}}</h4>
          <h3>Correct answers: {{this.firebaseService.correctAnswerCount}}/{{this.firebaseService.questionCount}}</h3>
          <p class="card-text">Time Elapsed : {{this.firebaseService.displayTimeElapsed()}}</p>
          <div [hidden]="this.success == true">
            <div> Read the cases and try to answer the questions again.</div>
            <a href="v2/cases" color="accent" class="btn" ><button mat-raised-button color="accent" >Go to cases</button></a>
          </div>
          <div [hidden]="this.success == false">
            <div class="alert alert-success">
              Congratulation !!!<br><strong>The certificate will be sent to your mailbox.</strong>
            </div>
          </div>
      
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>