import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from '../shared/services/firebase.service';
import { ArrayType } from '@angular/compiler';
import { AngularFireAnalytics } from '@angular/fire/analytics';


@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  isLoaded = false;

  constructor(private router: Router, public firebaseService: FirebaseService, public analytics: AngularFireAnalytics) {
    this.analytics.logEvent('exam_start');
    this.firebaseService.logVisit('exam_start');
  }

  ngOnInit() {
    // if (parseInt(localStorage.getItem('seconds')) > 0 && (localStorage.getItem('questions') != "undefined") ) {
    //   this.firebaseService.seconds = parseInt(localStorage.getItem('seconds'));
    //   this.firebaseService.questionProgress = 0;//parseInt(localStorage.getItem('questionProgress'));
    //   this.firebaseService.questions = JSON.parse(localStorage.getItem('questions'));
    //   if (this.firebaseService.questionProgress == this.firebaseService.questionCount)
    //     this.router.navigate(['/result']);
    //   else
    //     this.startTimer();
    // }
    // else 
    {
      this.firebaseService.seconds = 0;
      this.firebaseService.questionProgress = 0;
      this.firebaseService.getQuestions().subscribe(
      ref => {
        const allQuestions: any[] = ref;
        // Separa las preguntas por grupos
        const groups = allQuestions.reduce(function (obj, item) {
            obj[item.Case] = obj[item.Case] || [];
            obj[item.Case].push(item);
            return obj;
        }, {});
        // Cantidad de preguntas
        this.firebaseService.questionCount = Object.keys(groups).length;
        // Elije una sola progunta por grupo
        this.firebaseService.questions = [];
        Object.keys(groups).forEach(group => {
          this.firebaseService.questions.push(groups[group][Math.floor(Math.random()*groups[group].length)]);
        });
        // Guarda localmente
        localStorage.setItem('questions', JSON.stringify(this.firebaseService.questions));
        localStorage.setItem('questionProgress', this.firebaseService.questionProgress.toString());
        this.startTimer();
        this.isLoaded = true;
      }
    )
    }
  }

  public startTimer() {
    this.firebaseService.timer = setInterval(() => {
      this.firebaseService.seconds++;
      localStorage.setItem('seconds', this.firebaseService.seconds.toString());
    }, 1000);
  }

  public Answer(choice: number) {
    this.firebaseService.questions[this.firebaseService.questionProgress].Choice = choice;
    localStorage.setItem('questions', JSON.stringify(this.firebaseService.questions));
    this.firebaseService.questionProgress++;
    localStorage.setItem('questionProgress', this.firebaseService.questionProgress.toString());
    if (this.firebaseService.questionProgress == this.firebaseService.questionCount) {
      clearInterval(this.firebaseService.timer);
      this.router.navigate(['result']);
    }
  }

  public Back() {
    if (this.firebaseService.questionProgress > 0)
      this.firebaseService.questionProgress--;
  }

}
