<!-- footer -->
<footer class="footer">
    <div class="copyright-section">
        <div class="container text-center">
            <span class="copytext">Copyright &copy; 2018-2021 | <strong><a href="http://ishne.org/">ISHNE</a></strong></span>
        </div>
        <div class="container text-center">
            <span class=""><a [routerLink]="['/privacy']">Privacy Policy</a> - <a [routerLink]="['/tac']">Terms and Conditions</a></span>
        </div>
        <div class="container text-center">
            <span class="">Update v2.1.4</span>
        </div>
    </div>
</footer>