<app-header [title]="this.title"></app-header>


<!-- case -->
<section class="about-text">
    <div class="container">
        <div class="row col-md-12">
		
            <div class="iub_header">
                <h1>Privacy Policy of <strong>ecguniversity.org</strong></h1>
                <p>This Application collects some Personal Data from its Users.</p>
            </div> <!-- /header -->
       
            <div class="one_line_col">
                <h2 id="owner_of_the_data">Owner and Data Controller</h2>
                <p>International Society for Holter and Noninvasive Electrocardiology</p>
                <p><b>Owner contact email: </b>info [at] ecguniversity.org</p>
            </div> <!-- /one_line_col -->


            <div class="one_line_col">
            <h2 id="types_of_data">Types of Data collected</h2>
                <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies, Usage Data, first name, email address, profession and workplace.</p>
                <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br>The Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.<br>All Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without any consequences on the availability or the functioning of the service.<br>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br>Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
                <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>
            </div> <!-- /one_line_col -->


            <div class="one_line_col">
                <h2 id="place_of_processing">Mode and place of processing the Data</h2>
                <h3>Methods of processing</h3>
                <p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.</p>
                <h3>Place</h3>
                <p>The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.</p>
                <h3>Retention time</h3>
                <p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data.</p>
            </div> <!-- /one_line_col -->


            <div class="one_line_col">
                <h2 id="use_collected_data">The use of the collected Data</h2>
                <p>The Data concerning the User is collected to allow the Owner to provide its services, as well as for the following purposes: Analytics, Contacting the User and Registration and authentication.</p>
                <p>The Personal Data used for each purpose is outlined in the specific sections of this document.</p>
            </div> <!-- /one_line_col -->
 
    <div class="one_line_col">

      <h2 id="data_processing_detailed_info">Detailed information on the processing of Personal Data</h2>
      <p>Personal Data is collected for the following purposes and using the following services:</p>

      <ul class="for_boxes">


           <li>
            <div class="box_primary box_10 expand collapsed">
               <h3 class="expand-click w_icon_24 policyicon_purpose_4398496">Analytics</h3>
               <div class="expand-content" >

                   <p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>


                   <h4>Google Analytics (Google Inc.)</h4>

                    <p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.</p>
                    <p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>



                    <p>Personal Data collected: Cookies and Usage Data.</p>
                    <p>Place of processing: US –
                        <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a>
						–
                        <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">Opt Out</a>
                    </p>

                  

               </div> <!-- /expand-content -->
             </div> <!-- /expand -->
           </li>

        
           <li>
            <div class="box_primary box_10 expand collapsed">
               <h3 class="expand-click w_icon_24 policyicon_purpose_4398497">Contacting the User</h3>
               <div class="expand-content" >

                   


                   <h4>Contact form (this Application)</h4>

                   <p>By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.</p>



                     <p>
                      Personal Data collected:
                       first name.
                     </p>



                                    <h4>Mailing list or newsletter (this Application)</h4>

                   <p>By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Application. Your email address might also be added to this list as a result of signing up to this Application or after making a purchase.</p>



                     <p>
                      Personal Data collected:
                       email address.
                     </p>



                  

               </div> <!-- /expand-content -->
             </div> <!-- /expand -->
           </li>

        
           <li>
            <div class="box_primary box_10 expand collapsed">
               <h3 class="expand-click w_icon_24 policyicon_purpose_4399183">Registration and authentication</h3>
               <div class="expand-content" >

                   <p>By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services.<br>
Depending on what is described below, third parties may provide registration and authentication services. In this case, this Application will be able to access some Data, stored by these third-party services, for registration or identification purposes.</p>


                   <h4>Direct registration (this Application)</h4>

                   <p>The User registers by filling out the registration form and providing the Personal Data directly to this Application.</p>



                     <p>
                      Personal Data collected:
                       email address, first name, profession and workplace.
                     </p>



                  

               </div> <!-- /expand-content -->
             </div> <!-- /expand -->
           </li>

         

      </ul>

    </div> <!-- /one_line_col -->

   

 



 

	

<div class="one_line_col">

  <h2 id="further_data_processing_info">Additional information about Data collection and processing</h2>

  <h3>Legal action</h3>
  <p>
    The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action arising from improper use of this Application or the related services.<br>The User declares to be aware that the Data Controller may be required to reveal personal data upon request of public authorities.
  </p>

  <h3>Additional information about User's Personal Data</h3>
  <p>
    In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular services or the collection and processing of Personal Data upon request.
  </p>

  <h3>System logs and maintenance</h3>
  <p>
    For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) or use for this purpose other Personal Data (such as IP Address).
  </p>

  <h3>Information not contained in this policy</h3>
  <p>
    More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the contact information at the beginning of this document.
  </p>

  <h3>The rights of Users</h3>
  <p>
    Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller at the contact information set out above.
  </p>
	  <p>
	    This Application does not support “Do Not Track” requests.<br>To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
	  </p>

  <h3>Changes to this privacy policy</h3>
  <p>
    The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this Application and can request that the Data Controller remove the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.
  </p>

  <h3>Information about this privacy policy</h3>
  <p>
    The Data Controller is responsible for this privacy policy, prepared starting from the modules provided by Iubenda and hosted on Iubenda's servers.
  </p>

</div> <!-- /one_line_col -->

<div class="one_line_col">

  <div class="box_primary box_10 definitions expand collapsed">

    <h3 class="expand-click w_icon_24 icon_ribbon">
    	Definitions and legal references
    </h3>

    <div class="expand-content" >

      <h4>Personal Data (or Data)</h4>
      <p>Any information regarding a natural person, a legal person, an institution or an association, which is, or can be, identified, even indirectly, by reference to any other information, including a personal identification number.</p>

      <h4>Usage Data</h4>
      <p>Information collected automatically from this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>

      <h4>User</h4>
      <p>The individual using this Application, which must coincide with or be authorized by the Data Subject, to whom the Personal Data refers.</p>

      <h4>Data Subject</h4>
      <p>The legal or natural person to whom the Personal Data refers.</p>

      <h4>Data Processor (or Data Supervisor)</h4>
      <p>The natural person, legal person, public administration or any other body, association or organization authorized by the Data Controller to process the Personal Data in compliance with this privacy policy.</p>

      <h4>Data Controller (or Owner)</h4>
      <p>The natural person, legal person, public administration or any other body, association or organization with the right, also jointly with another Data Controller, to make decisions regarding the purposes, and the methods of processing of Personal Data and the means used, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</p>

      <h4>This Application</h4>
      <p>The hardware or software tool by which the Personal Data of the User is collected.</p>

            <h4>Cookies</h4>
            <p>Small piece of data stored in the User's device.</p>


      <hr>

      <h4>Legal information</h4>
      <p>Notice to European Users: this privacy statement has been prepared in fulfillment of the obligations under Art. 10 of EC Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive 2009/136/EC, on the subject of Cookies.</p>
      <p>This privacy policy relates solely to this Application.</p>

    </div> <!-- /expand-content -->

  </div> <!-- /box_primary -->

</div> <!-- /one_line_col -->

	
            <div class="iub_footer">
                <p>Latest update: February 23, 2018</p>
                <!--
                <p>
                <a href="//www.iubenda.com" title="iubenda - Privacy Policy generator" target="_blank">iubenda</a> hosts this content and only collects
                <a href="//www.iubenda.com/privacy-policy/877900" target="_blank">the Personal Data strictly necessary</a> for it to be provided
                </p>
                <a href="//www.iubenda.com/private/privacy-policy/1072873?preview=true&amp;ifr=true&amp;height=800&amp;an=no" title="Show the simplified Privacy Policy" class="show_comp_link iframe-preserve an-preserve">Show the simplified Privacy Policy</a>-->
            </div> <!-- /footer -->


		</div>
	</div>
</section>


<app-footer></app-footer>