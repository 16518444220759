import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacypolicyComponent implements OnInit {

  title = 'Privacy Policy';

  constructor(private firebaseService: FirebaseService, private analytics: AngularFireAnalytics) {
    analytics.logEvent('privacypolicy');
    firebaseService.logVisit('privacypolicy');
  }

  ngOnInit() {
    //window.scrollTo({top: 0, left: 0})
  }

}
