import { Routes } from '@angular/router';
import { RouterModule } from  '@angular/router';
import { NgModule } from '@angular/core';

import { CasesV2Component } from './cases-v2.component';
import { casesV2 } from './rosterV2';

const routes: Routes = [
  { path: '', component: CasesV2Component  }
];

// const routes: Routes = [
//   // { path: 'v2/case', component: CaseComponent,
//   //   children: [
//   //     { path: 'baranchuk', component: BaranchukComponent }
//   //   ]
//   // },
//   { path: 'v2/case/baranchuk', component: BaranchukComponent },
//   // { path: ':name', component: CaseComponent },
//   { path: '', component: BaranchukComponent  },
// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CasesV2RoutingModule { }