import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../shared/services/firebase.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})

export class SubscriptionComponent implements OnInit {

  title = 'Subscription';

  public regiter = -1;
  public email = '';
  public message = '';
  

  constructor(private route: ActivatedRoute, public firebaseService : FirebaseService, private analytics: AngularFireAnalytics) {
    this.route.queryParams.subscribe(params => {
      this.regiter = params['r'];
      //this.email = Buffer.from(params['e'], 'base64').toString();
      this.email = atob(params['e']);
    });
  }

  ngOnInit(): void {
    if (this.email != '') {
      this.firebaseService.GetUser(this.email).subscribe(
        ref => {
          if (this.regiter == 1 && ref.exists)
          {
            this.firebaseService.Subscribe(this.email);
            this.analytics.logEvent('Subscribe');
            this.firebaseService.logVisit('Subscribe');
            this.message = 'Thank you for subscribing toecguniversity.org Newsletter. Your subscription has been confirmed.';
          }
          else if (this.regiter == 0 && ref.exists)
          {
            this.firebaseService.Unsubscribe(this.email);
            this.analytics.logEvent('Unsubscribe');
            this.firebaseService.logVisit('Unsubscribe');
            this.message = 'You cancel your subscribtion to ecguniversity.org Newsletter. You can also continue learning from our website.';
          }
          else {
            this.message = 'Subscription failure. The link is invalid.';
          }
        }
      );
    } else {
      this.message = 'Subscription failure. The link is invalid.';
    }
    
  }

}
