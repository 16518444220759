<div [hidden]="!this.isLoaded">

  <app-header [title]="'Questions'" [subtitle]=""></app-header>

  <div class="quizz container">
    <div class="row" *ngIf="this.firebaseService.questions && this.firebaseService.questionProgress != this.firebaseService.questionCount" style="margin: 20px 0 60px 0;">
      <div class="col-lg-10 col-centered">

        <span class="quizz-timer">Time Elapsed : {{this.firebaseService.displayTimeElapsed()}}</span>
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="(this.firebaseService.questionProgress)/this.firebaseService.questionCount*100" aria-valuemax="100" [style.width.%]="(this.firebaseService.questionProgress)/this.firebaseService.questionCount*100"></div>
        </div>

        <div class="card border-secondary blue-grey darken-1">
          <div class="card-header">
            <p>Question {{this.firebaseService.questionProgress+1}}:</p>
            <h5 class="card-title">{{this.firebaseService.questions[this.firebaseService.questionProgress].Text}}</h5>
            <h5 class="card-title" *ngIf="this.firebaseService.questions[this.firebaseService.questionProgress].Text2 != undefined;">{{this.firebaseService.questions[this.firebaseService.questionProgress].Text2}}</h5>    
            <div *ngIf="this.firebaseService.questions[this.firebaseService.questionProgress].ImageName!=null">
              <img class="center" [src]="this.firebaseService.questions[this.firebaseService.questionProgress].ImageName" style="width:350px;height:200px;max-width: 100%;">
            </div>
          </div>
          <div class="card-body text-secondary">
            <ul class="collection answer">
                <ng-container *ngFor="let option of this.firebaseService.questions[this.firebaseService.questionProgress].Options;let i = index">
                  <hr [ngStyle]="{'display': i > 0 ? 'inherit' : 'none' }" />
                  <li class="collection-item" (click)="Answer(i)">
                    {{option}}
                  </li>
                </ng-container>
              </ul>
          </div>
        </div>
        <div class="mt-3">
            <button [disabled]="firebaseService.questionProgress <= 0" mat-raised-button (click)="Back()" color="primary" id="btnregister" class="btn show-register-form" style="padding: 0 40px;">Back</button>
        </div>

      </div>
    </div>
  </div>

  <app-footer></app-footer>

</div>
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js"></script> -->