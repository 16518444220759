import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-tac',
  templateUrl: './tac.component.html',
  styleUrls: ['./tac.component.scss']
})
export class TacComponent implements OnInit {
  
  title = 'Terms and Conditions';

  constructor(private firebaseService: FirebaseService, private analytics: AngularFireAnalytics) {
    analytics.logEvent('tac');
    firebaseService.logVisit('tac');
  }

  ngOnInit() {
    //window.scrollTo({top: 0, left: 0})
  }

}
