import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../shared/services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quiz-bar',
  templateUrl: './quiz-bar.component.html',
  styleUrls: ['./quiz-bar.component.css']
})
export class QuizBarComponent implements OnInit {

  constructor(private firebaseService : FirebaseService, private router : Router) { }

  ngOnInit() {
  }

  SignOut() {
    localStorage.clear();
    clearInterval(this.firebaseService.timer);
    this.router.navigate(['']);
  }
}
