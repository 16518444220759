<!-- Faculty -->
<!-- <section class="gallery" id="faculty">
    <div class="container">
        <h2 class="text-center">Faculty v2</h2>
        <ol class="image-list grid-view4">
            <li *ngFor="let author of authorsV2">
                <figure class="shadow">
                    <img src="{{author.avatar}}" alt="{{author.name}}">
                    <figcaption>
                        <p>{{author.name}}</p>
                        <p>{{author.institution}}</p>
                    </figcaption>
                </figure>
            </li>
        </ol>
    </div>

</section> -->

<section class="gallery" id="faculty">
    <div class="container">
        <h2 class="text-center">Faculty v2</h2>
        <div class="grid-wrapper">
            <div class="grid">
                <div class="grid-cell" *ngFor="let author of authorsV2">
                    <div class="grid-cell-inner">
                        <figure class="shadow">
                            <img src="{{author.avatar}}" alt="{{author.name}}">
                            <figcaption>
                                <p>{{author.name}}</p>
                                <p>{{author.institution}}</p>
                            </figcaption>
                        </figure>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>