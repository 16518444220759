<div class="container">
	<div class="about-section">
		<div class="form-box" style="background: rgba(21,122,160,.96);">
			<div class="form-top">
				<div class="form-top-left">
					<h3>Authors</h3>
				</div>
				<div class="form-top-right">
                    <i class="fa fa-user-md fa-4x" aria-hidden="true"></i>
				</div>
			</div>
			<div class="form-bottom">
				<div *ngFor="let author of authors; let i = index;" class="post-author">
					<div class="post-author__avatar">
						<img src="{{countries[i]}}" class="avatar flag" height="29" width="50">
						<img alt="{{author}}" src="{{avatar[i]}}" class="avatar avatar-109 photo">
					</div>
					<div class="post-author__info">
						<h3 class="post-author__title">
                            <div *ngIf="author_link != undefined; else elseBlock"><a target="_blank" href="{{author_link[i]}}">{{author}}{{med_titles[i]}}</a></div>
                            <ng-template #elseBlock>{{author}}{{med_titles[i]}}</ng-template>
                        </h3>
                        <div [innerHtml]="bib[i]"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>