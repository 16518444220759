import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../shared/services/firebase.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.css']
})
export class QuizResultComponent implements OnInit {

  UserName: string = '';
  success = false;
  resultMessage = '';
  resultBackground = '';
  successRate = 0.6;

  constructor(public firebaseService: FirebaseService, private router: Router, private snackbar: MatSnackBar, private analytics: AngularFireAnalytics) { }

  ngOnInit() {
    this.firebaseService.User = JSON.parse(localStorage.getItem('User'));

    if (parseInt(localStorage.getItem('questionProgress')) == this.firebaseService.questionCount) {
      this.firebaseService.seconds = parseInt(localStorage.getItem('seconds'));
      this.firebaseService.questionProgress = parseInt(localStorage.getItem('questionProgress'));
      this.firebaseService.questions = JSON.parse(localStorage.getItem('questions'));

      this.firebaseService.correctAnswerCount = 0;
      this.firebaseService.questions.forEach((e, i) => {
        if (e.Answer == e.Choice)
          this.firebaseService.correctAnswerCount++;
      });
      this.success = (this.firebaseService.correctAnswerCount / this.firebaseService.questionCount) > this.successRate;
      
      this.firebaseService.User.ExamPassed = this.success;
      this.firebaseService.User.ExamLastTest = (new Date()).getTime();
      this.firebaseService.User.ExamDownloadedSent = false;
      this.firebaseService.updateUserExam();
      
      if (this.success) {
        this.resultMessage = 'CONGRATULATION !!!';
        this.resultBackground = '../../../assets/images/exam-success.jpg';
        this.analytics.logEvent('exam_completion_success');
        this.firebaseService.logVisit('exam_completion_success');
      } else {
        this.resultMessage = 'TRY AGAIN !!!';
        this.resultBackground = '../../../assets/images/exam-fail.jpg';
        this.analytics.logEvent('exam_completion_fail');
        this.firebaseService.logVisit('exam_completion_fail');
      }
    }
    else
      this.router.navigate(['/quiz']);
  }

  /*OnSubmit() {
    this.firebaseService.submitScore().then(() => {
      this.restart();
    });
  }*/

  /*restart() {
    localStorage.setItem('questionProgress', "0");
    localStorage.setItem('questions', "");
    localStorage.setItem('seconds', "0");
    this.router.navigate(['/quiz']);
  }*/

  /*certificateRequest() {
    this.snackbar.open('Congratulation !!! The certificate will be sent to your mailbox.', '', {duration: 10000, panelClass: ['Info']});
  }*/

}
