import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from "@angular/fire/firestore";

import { MaterialModule } from './shared/modules/material.module';

// Modules
import { AppCommonModule } from './app-common/app-common.module';
import { CasesV2Module } from './versions/v2/cases-v2.module';
import { CaseModule } from './case/case-module.module';
// import { RosterV2Module } from './versions/v2/roster/roster.module';
// import { CasesRoutingModule } from './versions/cases-routing.module';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { QuizComponent } from './quiz/quiz.component'
import { QuizResultComponent } from './quiz/quiz-result/quiz-result.component'
import { QuizBarComponent } from './quiz/quiz-bar/quiz-bar.component'

import { CliniciansGalleryComponent } from './home/clinicians-gallery/clinicians-gallery.component';
import { SubscriptionComponent } from './subscription/subscription.component';

//import { HttpClientModule }    from '@angular/common/http';

//import { GoogleApiModule, GoogleApiService, GoogleAuthService, NgGapiClientConfig, NG_GAPI_CONFIG, GoogleApiConfig } from "ng-gapi";


// let gapiClientConfig: NgGapiClientConfig = {
//   client_id: "128281320074-99hao3egl6v65dreeiu0apdpseo3qkqd.apps.googleusercontent.com",
//   project_id:"ecguniversity",
//   discoveryDocs: ["https://analyticsreporting.googleapis.com/$discovery/rest?version=v4"],
//   //ux_mode: "redirect",
//   redirect_uri: "https://ecguniversity.firebaseapp.com/__/auth/handler","https://ecguniversity.web.app/__/auth/handler",
//   scope: ["https://www.googleapis.com/auth/analytics.readonly",   "https://www.googleapis.com/auth/analytics"].join(" ")
// };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, 
    QuizComponent, 
    QuizResultComponent,
    QuizBarComponent,
    CliniciansGalleryComponent,
    SubscriptionComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    MaterialModule,
    // CasesRoutingModule, // Tiene que estar antes que AppRoutingModule
    AppRoutingModule,
    AppCommonModule,
    CaseModule,
    // RosterV2Module,
    CasesV2Module,
    //HttpClientModule,
    //GoogleApiModule.forRoot( {provide: NG_GAPI_CONFIG, useValue: gapiClientConfig} ),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
