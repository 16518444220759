import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonRoutingModule } from './app-common-routing.module';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TacComponent } from './pages/tac/tac.component';
import { SocialButtonsComponent } from './components/social-buttons/social-buttons.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PrivacypolicyComponent, 
    TacComponent,
    SocialButtonsComponent, 
  ],
  imports: [
    CommonModule,
    AppCommonRoutingModule
  ],
  exports: [
    HeaderComponent, 
    FooterComponent,
    PrivacypolicyComponent, 
    TacComponent,
	  SocialButtonsComponent, 
  ]
})
export class AppCommonModule { }
