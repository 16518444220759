// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCQWQG_PvrKERw-vbGHU5XHCBBfF7k0Dps",
    authDomain: "ecguniversity.firebaseapp.com",
    databaseURL: "https://ecguniversity.firebaseio.com",
    projectId: "ecguniversity",
    storageBucket: "gs://ecguniversity.appspot.com",
    messagingSenderId: "128281320074",
    appId: "1:128281320074:web:70d8b4a57fcfb2fe",
    //measurementId: "G-F9QQVJ9FTT"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
