import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { casesV2 } from './rosterV2'
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-cases-v2',
  templateUrl: './cases-v2.component.html',
  styleUrls: ['./cases-v2.component.scss']
})

export class CasesV2Component {

  title = 'Roster of Educational Cases';
  cases = casesV2;

  constructor(public firebaseService: FirebaseService, private analytics: AngularFireAnalytics) {
    analytics.logEvent('case_v2');
    firebaseService.logVisit('case v2');
  }

}
