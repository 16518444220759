<nav class="top-content" style="padding-bottom:10px;overflow-anchor: none;">
        <div class="row">
            <div class="text-center text fill_div">
                <a [routerLink]="['']"><h1>ISHNE <span style="color:#FFA500;">ECG</span > UNIVERSITY</h1></a>
                <div class="description">
                    <p style="font-size: 160%;">{{title}}</p>
                    <p style="font-size: 150%;">{{subtitle}}</p>
                </div>
            </div>
        </div>
    </nav>