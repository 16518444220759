import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ArrayType } from '@angular/compiler';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AuthorComponent implements OnInit {

  @Input() avatar: ArrayType;
  @Input() authors: ArrayType;
  @Input() med_titles: ArrayType;
  @Input() countries: ArrayType;
  @Input() author_link: ArrayType;
  @Input() bib: ArrayType;

  constructor() { }

  ngOnInit() {
  }

}
