import { Component, OnInit, ViewChild, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';

declare var $: any;

@Component({
  selector: 'ecgchart',
  templateUrl: './ecgchart.component.html',
  styleUrls: ['./ecgchart.component.scss']
})
export class EcgChartComponent implements OnInit {
  @ViewChild('chart', {static: true}) chartEl: ElementRef<HTMLDivElement>;
  @ViewChild('row', {static: true}) rowEl: ElementRef<HTMLDivElement>;

  // Escala del ecg
  @Input() ecgX: number = 200;
  @Input() ecgY: number = 100;
  // Tamaño de la imagen en pixeles
  @Input() pixelX: number = 1000;
  @Input() pixelY: number = 500;

  showTag =  true;
  // Relacion de la imagen
  ratio: number;
  // Imagen de fondo
  @Input() backgroundImage: string = '';

  plot: any;
  data: any = [];
  options: any;

  // Control de los calipers
  dragging = false;
  dragLine = 0;
  vertical = 1;
  pos1 = 0;
  pos2 = 0;
  length = '';

  // Controlan el tamaño de la imagen de fondo
  xmax = this.pixelX;
  ymax = this.pixelY;

  // Margen del control
  marginX = 7;
  marginY = 7;

  // Color de fondo de botones
  colorButtonV = "#def";
  colorButtonH = "#eee";

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.ratio = this.pixelY / this.pixelX;
    this.resizeChart();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeChart();
  }

  resizeChart() {
    this.xmax = this.rowEl.nativeElement.offsetWidth < (+this.pixelX + 62)  ? (this.rowEl.nativeElement.offsetWidth - 62) : this.pixelX;
    this.ymax = this.xmax*this.ratio;

    this.showTag = this.ymax < 270 ? false : true;
    // this.marginX = this.chartEl.nativeElement.offsetLeft + 7;
    // this.marginY = this.chartEl.nativeElement.offsetTop + 7;
    // this.xmax =  this.chartEl.nativeElement.clientWidth-14;
    // this.ymax = this.chartEl.nativeElement.offsetWidth*this.ratio-14;
    // Cambia dinámicamente la altura
    this.renderer.setStyle(this.chartEl.nativeElement, 'width',  (+this.xmax + 15) + "px");
    this.renderer.setStyle(this.chartEl.nativeElement, 'height', (+this.ymax + 7) + "px");

    // this.xmax = this.backSizeX;
    // this.ymax = this.backSizeY;

    //Configura flot
    this.data =  [
			{ data: [[-1, 0], [-1, this.ymax]] },
			{ data: [[-1, 0], [-1, this.ymax]] }
		];

    this.options = {
      lines: {
        show: true
      },
      points: {
        show: false,
      },
      grid: {
        hoverable: true,
        clickable: true,
        editable: true,
				backgroundColor: null,
        borderWidth: 1,
        show: true
      },
      xaxis: {
				min: 0,
				max: this.xmax,
				show: false
			},
      yaxis: {
				min: 8,
				max: this.ymax,
				show: false
			},
      zoom: {
				interactive: true
			},
			pan: {
				interactive: true,
				enableTouch: true
			},
      legend: {
        show: false
      },
			colors: ["#FF0000", "#FF0000"],
    };

    this.drawChart();
  }

  drawChart() {
    if (!this.plot) {
      this.plot =  $.plot(this.chartEl.nativeElement, this.data, this.options);
    }
    else {
      this.plot.resize();
      this.plot.setupGrid(true);
      this.plot.draw();
    }
  }

  // onMouseEnter() {
  //   alert("Don't touch my bacon!")
  // }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: any) {
    if (event.layerX > this.marginX && event.layerY > this.marginY && event.layerX < (this.xmax + this.marginX) && event.layerY < (this.ymax + this.marginY)) {
      let posX = event.layerX - this.marginX;
      let posY = this.ymax - event.layerY + this.marginY;

      if (this.dragLine != 0) {
        this.dragging = true;
      } else {
        if (this.pos1 == 0) {
          if (this.vertical == 1) {
            this.pos1 = posX;
            this.data =  [ { data: [[this.pos1, 0], [this.pos1, this.ymax]] } ];
          } else {
            this.pos1 = posY;
            this.data =  [ { data: [[0, this.pos1], [this.xmax, this.pos1]] } ];
          }
        } else
        if (this.pos2 == 0) {
          if (this.vertical == 1) {
            this.pos2 = posX;
            this.data = [ { data: [[this.pos1, 0], [this.pos1, this.ymax]]}, {data: [[this.pos2, 0], [this.pos2, this.ymax]] } ];
          } else {
            this.pos2 = posY;
            this.data = [ { data: [[0, this.pos1], [this.xmax, this.pos1]]}, {data: [[0, this.pos2], [this.xmax, this.pos2]] } ];
          }
          this.showLength();
        } else
        {
          this.clean();
        }
        this.plot.setData(this.data);
        this.plot.draw();
      }
    }
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: any) {
    if (event.layerX > this.marginX && event.layerY > this.marginY && event.layerX < (this.xmax + this.marginX) && event.layerY < (this.ymax + this.marginY)) {
      let posX = event.layerX - this.marginX;
      let posY = this.ymax - event.layerY + this.marginY;

      if (this.dragging) {
        if (this.dragLine == 1) {
          this.pos1 = this.vertical == 1 ? posX : posY;
        } else if (this.dragLine == 2) {
          this.pos2 = this.vertical == 1 ? posX : posY;
        }
        if (this.pos2 == 0) {
          if (this.vertical == 1) {
            this.data = [ { data: [[this.pos1,0], [this.pos1,this.ymax]]} ];
          } else {
            this.data = [ { data: [[0,this.pos1], [this.xmax,this.pos1]]} ];
          }
        } else {
          if (this.vertical == 1) {
            this.data = [ { data: [[this.pos1, 0], [this.pos1, this.ymax]]}, {data: [[this.pos2, 0], [this.pos2, this.ymax]] } ];
          } else {
            this.data = [ { data: [[0, this.pos1], [this.xmax, this.pos1]]}, {data: [[0, this.pos2], [this.xmax, this.pos2]] } ];
          }
          this.showLength();
        }
        this.plot.setData(this.data);
        this.drawChart();
      } else {				
        this.dragLine = 0;
        $("body").css("cursor", "default");
        let cursor = this.vertical == 1 ? "w-resize" : "s-resize";
        let curPos = this.vertical == 1 ? posX : posY;
        if (this.pos1 >= curPos-2 && this.pos1 <= curPos+2) {
          this.dragLine = 1;
          $("body").css("cursor", cursor);
        } else if (this.pos2 >= curPos-2 && this.pos2 <= curPos+2) {
          this.dragLine = 2;
          $("body").css("cursor", cursor);
        }
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseup(event: MouseEvent) {
    this.dragging = false;
  }

  showLength() {
    //this.length = Math.abs(this.pos2 - this.pos1);
    let dif = Math.abs(this.pos2 - this.pos1);
    dif = this.vertical == 1 ? dif * this.ecgX / this.xmax : dif * this.ecgY / this.ymax;
    let len = this.vertical == 1 ? (dif * 1000)/25 : dif /10;
    this.length = this.vertical == 1 ? len.toFixed(0) + " ms" : len.toFixed(2) + " mV";
  }

  clean() {
    this.data = [];
    this.pos1 = 0;
    this.pos2 = 0;
    this.length = null;
  }

  buttonVerticalClick() {
    this.colorButtonV = "#def";
    this.colorButtonH = "#eee";
	  this.vertical = 1;
    this.clean();
    this.plot.setData(this.data);
    this.plot.draw();
  }

  buttonHorizontalClick() {
    this.colorButtonV = "#eee";
	  this.colorButtonH = "#def";
    this.vertical = 0;
    this.clean();
    this.plot.setData(this.data);
    this.plot.draw();
  }

}
