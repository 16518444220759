import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasesV2RoutingModule } from './cases-v2-routing.module';
import { AppCommonModule } from '../../app-common/app-common.module';

import { CasesV2Component } from './cases-v2.component';


@NgModule({
  declarations: [
    CasesV2Component,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    CasesV2RoutingModule
  ],
  exports: [
  ]
})
export class CasesV2Module { }
