import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from '../shared/services/firebase.service';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HomeComponent implements OnInit {

  title = 'ecguniversity';
  
  OptionsProfStatus: string[] = ['Profesional Status', 'Student', 'Fellow', 'Nurse', 'Technician / Engineer', 'Medical Doctor', 'Other'];
  OptionsSpecialties: string[] = ['Specialty', 'Internist', 'Cardiologist', 'ER', 'Family Medicine', 'Psychiatry', 'Anesthesia', 'Critical care'];
  OptionsInstitutions: string[] = ['Institution', 'Academic Hospital', 'Non Academic hospital', 'out patient practice-clinic'];
  OptionsHowFind: string[] = ['How did you find out our course', 'By Internet', 'By a colleague', 'By email', 'Other'];

  // Patron para validar el email
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  // Muestra la pestaña de registro por defecto
  showRegister: boolean = true;


  constructor(private titleService: Title, public analytics: AngularFireAnalytics, public firebaseService : FirebaseService, private  router: Router, private snackbar: MatSnackBar) {
    analytics.logEvent('page_loaded', {'home':'yes'});
    firebaseService.logVisit('home');
  }

  ngOnInit() {
    this.titleService.setTitle( "ECG University");
    //localStorage.clear();
    if (this.firebaseService.User.email == "") {
      let user = localStorage.getItem('User');
      if (user != null) {
        this.firebaseService.User = JSON.parse(user);
      }
    }
  }

  selectLoginRegister(value: boolean) {
    //this.firebaseService.getVisitis();
    this.showRegister = value;

    // // Importar usuarios
    // for (let n = 0; n < this.users.users.length; n++) {
    //   this.firebaseService.AddExistingUser(this.users.users[n].name, this.users.users[n].email.toLowerCase(), this.users.users[n].prof_status, this.users.users[n].medical_doctor,
    //     this.users.users[n].institution, this.users.users[n].how_find,new Date(this.users.users[n].register_date),this.users.users[n].subscribed == 1, this.users.users[n].Unsubscribed == 1).then(
    //     ref =>{
          
    //     }
    //   );
    // }
    // let snackBarRef = this.snackbar.open('Finishhhhh.', '', {duration: 500000, panelClass: ['Info']});
  }

  signMeUp(name: string, email: string, prof_status: string, speciality: string, institution: string, find: string) {

    if (prof_status == this.OptionsProfStatus[0]) prof_status = '';
    if (speciality == this.OptionsSpecialties[0]) speciality = '';
    if (institution == this.OptionsInstitutions[0]) institution = '';
    if (find == this.OptionsHowFind[0]) find = '';
    let lowerEmail = email.toLowerCase();
    this.firebaseService.GetUser(lowerEmail).subscribe(
      ref => {
        if (!ref.exists) {
          
          this.firebaseService.SignUpUser(name, lowerEmail, prof_status, speciality, institution, find).subscribe(
          ref => {
              if (ref == undefined) {
                this.snackbar.open('Unexpected error. Please try again.', '', {duration: 5000, panelClass: ['Error']});
              } else {
                localStorage.clear();
                this.firebaseService.User = ref.data();
                this.firebaseService.setUser(ref.data()['name'], email);
                this.analytics.logEvent('new_user', { method: 'email'});
                this.firebaseService.logVisit('new_user');
              }
            }
          );
        } else {
          this.showRegister=false;
          this.snackbar.open('Already registered. Use your email to login.', '', {duration: 5000, panelClass: ['Info'], verticalPosition: 'top'});
        }
      }
    );
  }

  public signIn(email: string) {

    if (email.length > 0) {
      let lowerEmail = email.toLowerCase();
      this.firebaseService.GetUser(lowerEmail).subscribe(
        ref => {
          if (ref.data() != undefined) {
            this.firebaseService.Login(email, ref.data());
            this.analytics.logEvent('login', { method: 'email'});
          } else {
            let snackBarRef = this.snackbar.open('User not registered.', '', {duration: 5000, panelClass: ['Error']});
          }
        }
      );
    }
    else {
      this.snackbar.open('Enter a valid email.', '', {duration: 5000, panelClass: ['Error']});
    }
  }

  // public copyAuthor(name: string) {
  //   this.firebaseService.copyColection();
  // }

  public Logout () {
    localStorage.clear();
    this.firebaseService.User.email = '';
  }

  public startExam() {
    let state = this.firebaseService.examButtonState();
    switch (state) {
      case 1: this.router.navigate(['exam']); break;
      case 2: this.firebaseService.downloadCertificate(); break;
    }
    
  }


  public sendMessage(name: string, email: string, subject: string, message: string) {
    if (name.length > 0 && email.length > 0 && subject.length > 0 && message.length > 0) {
      this.firebaseService.saveMessage(name, email, subject, message);
      this.snackbar.open('Message sent. Thank you for contacting us.', '', {duration: 10000, panelClass: ['Info']});
      this.analytics.logEvent('contactform_message');
      this.firebaseService.logVisit('ontactform_message');
    }
    else {
      this.snackbar.open('Please complete all fields', '', {duration: 5000, panelClass: ['Error']});
    }
  }

  /*public createPDF() {
    const convertHTMLToPDF = require("pdf-puppeteer");
 
    var callback = function (pdf) {
        // do something with the PDF like send it as the response
        //res.setHeader("Content-Type", "application/pdf");
        //res.send(pdf);
    }

    convertHTMLToPDF(html, callback, options, puppeteerArgs, remoteContent);
  }*/

  users = 
  {
    "users": [
      {
        "name": "Karla Estefanía Martínez Nabor",
        "email": "karla.estefanianabor@hotmail.com",
        "prof_status": "Student",
        "medical_doctor": "",
        "institution": "Non Academic hospital",
        "how_find": "By Internet",
        "register_date": "2018-04-11 16:02:03",
        "subscribed": 0,
        "Unsubscribed": 0
      },
      {
        "name": "David Gálvez Caballero",
        "email": "davidgalvezc@yahoo.com",
        "prof_status": "Medical Doctor",
        "medical_doctor": "",
        "institution": "Academic Hospital",
        "how_find": "By Internet",
        "register_date": "2018-03-13 00:06:54",
        "subscribed": 0,
        "Unsubscribed": 0
      },
      {
        "name": "Judith Rut Peralta Carrasco",
        "email": "jupeca25@hotmail.com",
        "prof_status": "Medical Doctor",
        "medical_doctor": "",
        "institution": "Non Academic hospital",
        "how_find": "By Internet",
        "register_date": "2018-04-11 23:17:00",
        "subscribed": 0,
        "Unsubscribed": 0
      },
      {
        "name": "Lidia Sánchez Martínez",
        "email": "lidiasanchezm91@hotmail.com",
        "prof_status": "Enfermero",
        "medical_doctor": "",
        "institution": "Academic hospital",
        "how_find": "By Internet",
        "register_date": "2020-05-10 05:49:57",
        "subscribed": 0,
        "Unsubscribed": 0
      }
    ]
  };


}
