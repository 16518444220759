import { Routes } from '@angular/router';
import { RouterModule } from  '@angular/router';
import { NgModule } from '@angular/core';

// import { CaseComponent } from './case/case.component';


const routes: Routes = [
    // { path: '', component: CaseComponent},
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [RouterModule]
})
export class CaseRoutingModule { }
