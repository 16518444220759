import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
//import { TacComponent } from './pages/tac/tac.component';


const routes: Routes = [

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppCommonRoutingModule { }
