<app-header [title]="this.title"></app-header>

<section class="about-text">
    <div class="container">
        <div class="row">
            <div class="text-center fill_div padding">
                <h2>Subscription</h2>
                <p>{{message}}</p>
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>