import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { CaseRoutingModule } from './case-routing.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { AuthorComponent } from './author/author.component';
import { CaseComponent } from './case/case.component';
import { EcgChartComponent } from './ecgchart/ecgchart.component';

@NgModule({
  declarations: [
    CaseComponent,
    AuthorComponent,
    EcgChartComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    // BrowserModule,
    CaseRoutingModule
  ],
  exports: [
    CaseComponent,
    EcgChartComponent
  ]
})
export class CaseModule { }
