<app-header [title]="this.title"></app-header>

<!-- <div class="container">
    <ol class="grid-view2">
        <a *ngFor="let case of cases | keyvalue" [routerLink]="['/v2/case/', case.value.id]">
            <div class="card text-white bg-info mb-3">
                <div class="card-header">Case #{{case.value.num}}</div>
                <div class="card-body">
                    <div *ngFor="let avatar of case.value.avatar" class="case-split list-case-avatar">
                        <img src="{{avatar}}" class="media-photo">
                    </div>
                    <div *ngFor="let author of case.value.authors; let j = index">
                        <h5 class="card-title">{{author}}<br></h5> -->
                        <!-- <p class="card-text">{{case.institution[j]}}<br></p> -->
                    <!-- </div>
                </div>
            </div>  
        </a>
    </ol>
</div> -->

<div class="cases">
    <div class="inner-cases text-center">
        <div class="grid-wrapper">
            <div class="grid">
                <div class="grid-cell" *ngFor="let case of cases">
                    <div class="card text-muted">
                        <div class="card-body">
                            <span class="card-text">Case #{{case.num}}</span>
                            <p class="card-text">presented by:</p>
                            <div *ngIf="case.avatar.length == 1">
                                <img src="{{case.avatar[0]}}" class="media-photo">
                            </div>
                            <div *ngIf="case.avatar.length == 2">
                                <img src="{{case.avatar[1]}}" class="media-photo photo21">
                                <img src="{{case.avatar[0]}}" class="media-photo photo22">
                            </div>
                            <div *ngIf="case.avatar.length == 3">
                                <img src="{{case.avatar[2]}}" class="media-photo photo31">
                                <img src="{{case.avatar[1]}}" class="media-photo photo32">
                                <img src="{{case.avatar[0]}}" class="media-photo photo33">
                                </div>
                            <div *ngIf="case.avatar.length == 4">
                                <img src="{{case.avatar[3]}}" class="media-photo photo41">
                                <img src="{{case.avatar[2]}}" class="media-photo photo42">
                                <img src="{{case.avatar[1]}}" class="media-photo photo43">
                                <img src="{{case.avatar[0]}}" class="media-photo photo44">
                            </div>
                                
                            <div *ngFor="let author of case.authors; let j = index">
                                <h5 class="card-title">{{author}}</h5>
                            </div>
                            <div *ngIf="case.authors.length == 1">
                                <h5 class="card-title">&nbsp;</h5>
                            </div>
                            <br>
                            <button type="button" class="btn btn-info btn-block" [disabled]="case.id == ''" [routerLink]="['/v2/case/', case.id]">Read</button>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Visits Counter -->
    <div class="visits col-centered">
        <div class="text-center">
            <h4>visits: {{this.firebaseService.visits.case2}}</h4>
        </div>
    </div>
</div>



<app-footer></app-footer>