<div *ngIf="isLoaded">

    <app-header [title]="title" [subtitle]="subtitle"></app-header>

    <!-- <p>Current case: {{this.name}}</p> -->

    <!-- <div [ngSwitch]="this.name">
        <app-scheinman2 *ngSwitchCase="'scheinman2'"></app-scheinman2>
        <div *ngSwitchDefault>Invalid case name</div>
    </div> -->

    <!-- <ecgchart ecgX=179 ecgY=120 pixelX=960 pixelY=637 backgroundImage="'/assets/cases/case_chevalier_01.jpg'" style="width: 100%;"></ecgchart>-->
    <!-- <div [innerHtml]="myTemplate"></div> -->
    <!-- <dynamic-html [content]="myTemplate"></dynamic-html> -->


    <router-outlet></router-outlet>

    <app-author [authors]="authors" [med_titles]="med_titles" [countries]="countries" [author_link]="author_link" [bib]="bib" [avatar]="avatar"></app-author>

    <app-footer></app-footer>

    <!-- <script src="../../../assets/js/jquery.flot.js?1"></script>
    <script src="../../../assets/js/jquery.flot.mouse.js"></script>
    <<script src="<?=base_url()?>assets/js/jquery.flot.touch.js?1"></script>-->
    <!-- <script src="../../../assets/js/ecgchart.js?6"></script> -->

</div>