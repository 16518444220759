<div #row style="width: 100%;">
    <div class="demo-container">
        <div class="placeholder" style="position:static;" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')','width.px': xmax,'height.px': ymax}" [style.background-size]="xmax + 'px ' + ymax + 'px'">
            <div class="flotchart" #chart></div>
        </div>
        <div id="plot_menu">
            <button id="verticalBar1" class="verticalBar" [ngStyle]="{'background-color': colorButtonV}" (click)="buttonVerticalClick()"><img src="../assets/images/verbar.png" Style="width: 24px; height: 24px;"></button>
            <button id="horizontalBar1" class="horizontalBar" [ngStyle]="{'background-color': colorButtonH}" (click)="buttonHorizontalClick()"><img src="../assets/images/horbar.png" Style="width: 24px; height: 24px;"></button>
            <div class="caliperscontent" [hidden]="!showTag"><div class="caliperstag">USE YOUR CALIPERS</div></div>
        </div>
        <div class="Legend">Interval: {{length}}<span id="length2"></span></div>
    </div>
</div>
