import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/services/firebase.service';

@Component({
  selector: 'app-clinicians-gallery',
  templateUrl: './clinicians-gallery.component.html',
  styleUrls: ['./clinicians-gallery.component.scss']
})
export class CliniciansGalleryComponent implements OnInit {

  //authorsV1: any;
  authorsV2: any;

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit() {
    this.authorsV2 = this.firebaseService.getAuthors();
    //this.authorsV1 = this.firebaseService.getAuthorsV1();
   }

  
}
