type Case = {
    id: string;
    num: string;
    avatar: string[];
    authors: string[];
    titles: string[];
	institution: string[];
	country: string[];
	link: string[];
	bib: string[];
}

export let casesV2: Case[] = [
    {
        "id": "baranchuk",
        "num": "2.1",
        "avatar": ["assets/authors/baranchuk.jpg"],
        "authors": ["Adrian Baranchuk MD"],
        "titles": [" FACC FRCPC FCCS"],
        "institution": ["Queen's University"],
        "country": ["assets/flags/canada.gif"],
        "link": ["http://deptmed.queensu.ca/faculty/adrian_baranchuk"],
        "bib": ["Professor of Medicine (Tenure)<br>Queen's University<br>Kingston, Ontario, Canada<br>Editor-in-Chief, Journal of Electrocardiology<br>Vice President, International Society of Holter and Nonivasive Electrocardiology (ISHNE)<br>Secretary, Interamerican Society of Cardiology<br>"]
    }, {
        "id": "ikeda",
        "num": "2.2",
        "avatar": ["assets/authors/takanori.jpg"],
        "authors": ["Takanori Ikeda, MD"],
        "titles": [" PhD"],
        "institution": ["Toho University Faculty of Medicine"],
        "country": ["assets/flags/japan.gif"],
        "link": ["https://www.iscpcardio.org/governor/takanori-ikeda/"],
        "bib": ["Professor of Medicine<br>Toho University Faculty of Medicine, Tokyo, Japan<br>Vice President, International Society for Holter and Noninvasive Electrocardiology<br>Council Member, International Society of Electrocardiology<br>Trustee, Japanese Circulation Society<br>Trustee, Japanese Heart Rhythm Society<br>Associate Editor, Some International and Domestic Academic Journals"]
    }, {
        "id": "knight",
        "num": "2.3",
        "avatar": ["assets/authors/knight.jpg", "assets/authors/medical-doctor.jpg"],
        "authors": ["Bradley P. Knight, MD", "Jessica Charron, APN"],
        "titles": [""],
        "institution": ["Northwestern University", "Northwestern University"],
        "country": ["assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif"],
        "link": ["https://www.feinberg.northwestern.edu/faculty-profiles/az/profile.html?xid=18543",
            ""],
        "bib": ["Dr. Knight is the Chester C. and Deborah M. Cooley Distinguished Professor of Cardiology at Northwestern University, and has been the Director of Cardiac Electrophysiology at the Bluhm Cardiovascular Institute at Northwestern Memorial Hospital since November 1, 2009. He earned his BSPH at the University of North Carolina in Chapel Hill, and his MD at the Ohio State University. After completing his training in Medicine, Cardiology, and Cardiac Electrophysiology at the University of Michigan, he joined the Michigan faculty in 1997. He later served as the Director of Cardiac Electrophysiology at the University of Chicago from 2002 to 2009.",
            ""]
    }, {
        "id": "steinberg",
        "num": "2.4",
        "avatar": ["assets/authors/steinberg.jpg"],
        "authors": ["Jonathan S. Steinberg, MD"],
        "titles": [""],
        "institution": ["University Rochester School of Medicine & Dentistry"],
        "country": ["assets/flags/united_states_of_america.gif"],
        "link": ["https://www.radcliffecardiology.com/authors/jonathan-s-steinberg"],
        "bib": ["Professor of Medicine (adj)<br> University Rochester School of Medicine & Dentistry<br>Director, Arrhythmia Center<br>Summit Medical Group<br>New York and New Jersey, USA<br>"]
    }, {
        "id": "gutierrez",
        "num": "2.5",
        "avatar": ["assets/authors/gutierrez.jpg"],
        "authors": ["Oswaldo Gutierrez MD"],
        "titles": [""],
        "institution": ["Clínica de Diagnóstico Cardiovascular"],
        "country": ["assets/flags/costa_rica.gif"],
        "link": ["http://www.clinicacardiovascularcr.com/oswaldo_gutierrez.php"],
        "bib": ["Electrophysiologist. Cardiology Service, “México” Hospital, San José, Costa Rica<br>Professor of Medicine, University of Costa Rica<br>Member of the National Academy of Medicine of Costa Rica<br>Editorial member of The Journal of Electrocardiology"]
    }, {
        "id": "essebag",
        "num": "2.6",
        "avatar": ["assets/authors/toscani.jpg", "assets/authors/essebag.jpg"],
        "authors": ["Bruno Toscani MD", "Vidal Essebag MD"],
        "titles": ["", ", PhD, FRCPC, FACC, FHRS"],
        "institution": ["McGill University Health Centre", "McGill University Health Centre"],
        "country": ["assets/flags/canada.gif", "assets/flags/canada.gif"],
        "link": ["", ""],
        "bib": ["Electrophysiology Fellow at McGill University Health Centre - Canada<br>Electrophysiologist by Escola Paulista de Medicina – Brazil<br>Developer and professor of the interactive e-learning ECG tool “ECG 7 passos / ECG in 7 steps”",
                "Director of Cardiac Electrophysiology, McGill University Health Centre<br>Scientist, Research Institute of the McGill University Health Centre<br>Professor of Medicine, McGill University"]
    }, {
        "id": "dubner",
        "num": "2.7",
        "avatar": ["assets/authors/dubner2.jpg"],
        "authors": ["Sergio J. Dubner MD"],
        "titles": [", FACC"],
        "institution": [""],
        "country": ["assets/flags/argentina.gif"],
        "link": [""],
        "bib": ["Chief Electrophysiologic Service, Clinica y Maternidad Suizo Argentina and De los Arcos Sanatorio<br>Former President International Society for Holter and Noninvasive Electrocardiology (ISHNE)<br>Past Editor-in-Chief Cardiology Journal<br>Editorial Member Journal of the American College of Cardiology (JACC) Spanish version."]
    }, {
        "id": "williford",
        "num": "2.8",
        "avatar": ["assets/authors/williford.jpg", "assets/authors/orlov.jpg", "assets/authors/mcintyre2.jpg", "assets/authors/olshansky.jpg"],
        "authors": ["Noah Williford, MD", "Michael V. Orlov, MD", "Timothy McIntyre, MS", "Brian Olshansky, MD"],
        "titles": ["", ", PhD", ", BSEE, MSBME, IBHRE", ""],
        "institution": ["", "", "Boston Scientific", ""],
        "country": ["assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif", "assets/flags/united_states_of_america.gif"],
        "link": ["","https://physicians.capecodhealth.org/ma/hyannis/52-park-street-orlov.html","",""],
        "bib": ["Resident Physician, Internal Medicine<br>The University of Iowa Hospitals & Clinics",
                "Staff Electrophysiologist<br>Steward St. Elizabeth's Medical Center of Boston<br>EP Fellowship Program Director<br>Professor of Medicine<br>Tufts Medical School",
                "Tim McIntyre is a Principal Field Clinical Specialist with Boston Scientific in Boston, MA. Prior to this he was a Senior Tech Specialist with Abbott Labs/St. Jude Medical for 20 years. Tim has BSEE and MSBME degrees and is certified by IBHRE.",
                "Professor Emeritus, University of Iowa, Iowa City, IA<br>Clinical Cardiac Electrophysiologist<br>Covenant Hospital, Waterloo, IA and Mercy-North Iowa, Mason City IA USA"]
    }, {
        "id": "bazoukis",
        "num": "2.9",
        "avatar": ["assets/authors/bazoukis.jpg", "assets/authors/letsas.jpg"],
        "authors": ["George Bazoukis MD", "Konstantinos P. Letsas MD"],
        "titles": [", MSc", ", PhD, FEHRA"],
        "institution": ["Medical school of Ioannina", "Evangelismos General Hospital of Athens"],
        "country": ["assets/flags/greece.gif", "assets/flags/greece.gif"],
        "link": ["",""],
        "bib": ["I have graduated from the Medical School of Ioannina and I have completed the master’s degree in molecular and applied physiology at the Medical school of Athens. I am currently studying as a PhD student at the Medical school of Ioannina in the field of cardiac arrhythmias in heart failure patients while i am a cardiology fellow in the Second Department of Cardiology at the General Hospital of Athens “Evangelismos” in Greece. Also, I have participated in original research studies and systematic reviews/meta-analysis that have been published in high impact journals.",
                "Consultant Cardiologist-Electrophysiologist<br>Evangelismos General Hospital of Athens, Athens, Greece<br>Member of the EHRA certification examination committee for Invasive Cardiac Electrophysiology<br>Former EHRA Young EP ambassador for Greece<br>Editorial board member in International Journals"]
    }, {
        "id": "rubio",
        "num": "2.10",
        "avatar": ["assets/authors/rubio.jpg"],
        "authors": ["Jose Manuel Rubio MD"],
        "titles": [", PhD, FESC"],
        "institution": ["Universidad Autonoma de Madrid"],
        "country": ["assets/flags/spain.gif"],
        "link": ["https://www.fjd.es/es/cuadro-medico/jose-manuel-rubio-campal"],
        "bib": ["Associate Professor. Universidad Autonoma de Madrid. Spain<br>Chief of Arrhythmia Unit at Fundacion Jimenez Diaz- Madrid. Spain<br>Fellow of the European Society of Cardiology<br>Fellow of the Spanish Society of Cardiology<br>EHRA Certified Electrophysiology Specialist level 2<br>EHRA Gold member"]
    }, {
        "id": "goksel",
        "num": "2.11",
        "avatar": ["assets/authors/goksel.jpg"],
        "authors": ["Göksel Çinier MD"],
        "titles": [""],
        "institution": ["Kackar State Hospital, Rize, Turkey"],
        "country": ["assets/flags/turkey.gif"],
        "link": [""],
        "bib": ["Cardiac Electrophysiology Fellow<br>Department of Cardiology<br>Kackar State Hospital, Rize, Turkey<br>Coordinator of the International Society of Electrocardiology Young Community"]
    }, {
        "id": "green",
        "num": "2.12",
        "avatar": ["assets/authors/peretz.jpg", "assets/authors/sadek.jpg", "assets/authors/martingreen2.jpg"],
        "authors": ["Maude Peretz-Larochelle MD", "Mouhannad Sadek MD", "Martin Green MD"],
        "titles": [", MDCM, FRCPC", "", ", FRCPC"],
        "institution": ["University of Ottawa Heart Institute, Canada", "University of Ottawa Heart Institute, Canada", "University of Ottawa Heart Institute, Canada"],
        "country": ["assets/flags/canada.gif", "assets/flags/canada.gif", "assets/flags/canada.gif"],
        "link": ["",
                 "https://www.ottawaheart.ca/physician-researcher-profile/sadek-mouhannad",
                 "https://www.ottawaheart.ca/physician-researcher-profile/green-martin"],
        "bib": ["Dr. Maude Peretz-Larochelle is a Cardiologist and Intensivist currently completing a device fellowship at the University of Ottawa Heart Institute.<br>Her major interests include Critical Care Cardiology and medical education.",
                "Dr. Martin Green is Professor of Medicine (Cardiology) at the University of Ottawa Heart Institute.<br>His major interests include ECG recognition of arrhythmias and both pharmacological and nonpharmacological treatment of cardiac arrhythmias.",
                "Dr. Mouhannad Sadek is a cardiac arrhythmia specialist at The Ottawa Hospital and the University of Ottawa Heart Institute.<br>His major interests include ablation of complex arrhythmias and cardiac implantable electronic device extraction and management."]
    }, {
        "id": "korantzopoulos",
        "num": "2.13",
        "avatar": ["assets/authors/bazoukis.jpg", "assets/authors/letsas.jpg", "assets/authors/korantzopoulos.jpg"],
        "authors": ["George Bazoukis MD", "Konstantinos P. Letsas MD", "Panagiotis Korantzopoulos MD"],
        "titles": [", MSc", ", PhD, FEHRA", ", PhD, FEHRA"],
        "institution": ["", "", "University of Ioannina (Greece)"],
        "country": ["assets/flags/greece.gif", "assets/flags/greece.gif", "assets/flags/greece.gif"],
        "link": ["",
                 "",
                 "https://med.uoi.gr/index.php?option=com_content&view=article&id=620:panagiotis-korantzopoulos&catid=26&lang=en&Itemid=101"],
        "bib": ["",
                "",
                "Associate Professor in the First Department of Cardiology of Ioannina Medical School, Greece.<br>He is an arrhythmia and electrophysiological device specialist.<br>He has been awarded the EHRA accreditation in Cardiac Pacing and Implantable Cardiac Defibrillators and he is also Fellow of the EHRA. Research interests: arrhythmias, atrial fibrillation, Brugada syndrome, pacemakers, ICDs, electrocardiology, repolarization indexes, meta-analyses.<br>He is reviewer in 50 international biomedical journals and belongs to the Editorial Board of 2 international cardiovascular journals.<br>Publications in journals indexed in Pubmed: 224; Total citations: 3326; H-index: 29 (Scopus, December 2019)."]
    }, {
        "id": "pekrul",
        "num": "2.14",
        "avatar": ["assets/authors/pekrul.jpg", "assets/authors/medical-doctor.jpg", "assets/authors/veenhuyzen.jpg"],
        "authors": ["Christopher Pekrul MD", "D. Johnson MD", "George D. Veenhuyzen MD"],
        "titles": ["", "", ", FRCPC"],
        "institution": ["Libin Cardiovascular Institute, University of Calgary", "Libin Cardiovascular Institute, University of Calgary", "Libin Cardiovascular Institute, University of Calgary"],
        "country": ["assets/flags/canada.gif", "assets/flags/canada.gif", "assets/flags/canada.gif"],
        "link": ["",
                 "",
                 "https://libin.ucalgary.ca/node/343"],
        "bib": ["Clinical Fellow, Department of Cardiac Sciences and Medicine<br>Libin Cardiovascular Institute of Alberta, University of Calgary",
                "",
                "Associate Professor<br>Electrophysiology Training Program Director<br>Libin Cardiovascular Institute of Alberta, University of Calgary"]
    }, {
        "id": "goldschlager",
        "num": "2.15",
        "avatar": ["assets/authors/goldschlager.jpg"],
        "authors": ["Nora Goldschlager MD"],
        "titles": [""],
        "institution": ["Cardiology Division, San Francisco General Hospital<br>>University of California San Francisco"],
        "country": ["assets/flags/united_states_of_america.gif"],
        "link": [""],
        "bib": ["Professor of Clinical Medicine, UCSF<br>UCSF Kaiser Award for Excellence in Teaching in the Inpatient Care Setting<br>California Pacific Medical Center Excellence in Teaching in Cardiology<br>Academic Senate Distinction in Teaching Award<br>Emergency Medical Services and San Francisco Paramedic Association Hospital Provider of the Year Award"]
    }, {
        "id": "quiroga",
        "num": "2.16",
        "avatar": ["assets/authors/quiroga.jpg"],
        "authors": ["Horacio Quiroga Ponce MD"],
        "titles": [""],
        "institution": ["Arrhythmia Service, Hospital Aeronáutico Central. Buenos Aires, Argentina"],
        "country": ["assets/flags/argentina.gif"],
        "link": [""],
        "bib": ["Cardiac Electrophysiologist<br>Chief - Arrhythmia Service, Hospital Aeronáutico Central. Buenos Aires, Argentina<br>CRM Medical Advisor, Biotronik Southern Cone<br>Research and Clinical Fellow, Electrophysiology and Pacing, McMaster University (Hamilton ON, Canada)"]
    }
];