import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FirebaseService } from '../shared/services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public firebaseService : FirebaseService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (this.firebaseService.User.email == "") {
        let user = localStorage.getItem('User');
        if (user != null) {
          this.firebaseService.User = JSON.parse(user);
        }
      }
      if (this.firebaseService.User != null && this.firebaseService.User.email != '') {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
  }
  
}
