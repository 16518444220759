import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizResultComponent } from './quiz/quiz-result/quiz-result.component';
import { AuthGuard } from './auth/auth.guard';
// Cases
import { CaseComponent } from './case/case/case.component';
import { PrivacypolicyComponent } from './app-common/pages/privacypolicy/privacypolicy.component';
import { TacComponent } from './app-common/pages/tac/tac.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CertComponent } from './cert/cert.component';

const routes: Routes = [
  {path:'cases', redirectTo:'v2/cases'},
  {path:'v1/cases', loadChildren: () => import('./versions/v1/cases-v1.module').then(m => m.CasesV1Module) }, //'./cases/cases.module#CasesModule' },
  {path:'v2/cases', loadChildren: () => import('./versions/v2/cases-v2.module').then(m => m.CasesV2Module) },
  {path:'exam', component:QuizComponent, canActivate : [AuthGuard]},
  {path:'result',component:QuizResultComponent, canActivate : [AuthGuard]},
  {path:'privacy', component: PrivacypolicyComponent },
  {path:'tac', component: TacComponent },
  {path:'confirm_registration', component: SubscriptionComponent },
  {path:'cert', loadChildren: () => import('./cert/cert.component').then(m => m.CertModule) },
  {path:'cert/:routeParam', loadChildren: () => import('./cert/cert.component').then(m => m.CertModule) },
  {path:'', component:HomeComponent},
  {path:'**',redirectTo:''}
];

const routesCasesV2: Routes = [
  { path:'v2/case', component: CaseComponent,
      children: [
        { path: 'baranchuk',    loadChildren: () => import('./versions/v2/roster/baranchuk').then(m => m.BaranchukModule) },
        { path: 'ikeda',        loadChildren: () => import('./versions/v2/roster/ikeda').then(m => m.IkedaModule) },
        { path: 'knight',       loadChildren: () => import('./versions/v2/roster/knight').then(m => m.KnightModule) },
        { path: 'steinberg',    loadChildren: () => import('./versions/v2/roster/steinberg').then(m => m.SteinbergModule) },
        { path: 'gutierrez',    loadChildren: () => import('./versions/v2/roster/gutierrez').then(m => m.GutierrezModule) },
        { path: 'essebag',      loadChildren: () => import('./versions/v2/roster/essebag').then(m => m.EssebagModule) },
        { path: 'dubner',       loadChildren: () => import('./versions/v2/roster/dubner').then(m => m.DubnerModule) },
        { path: 'williford',    loadChildren: () => import('./versions/v2/roster/williford').then(m => m.WillifordModule) },
        { path: 'bazoukis',     loadChildren: () => import('./versions/v2/roster/bazoukis').then(m => m.BazoukisModule) },
        { path: 'rubio',        loadChildren: () => import('./versions/v2/roster/rubio').then(m => m.RubioModule) },
        { path: 'goksel',       loadChildren: () => import('./versions/v2/roster/goksel').then(m => m.GokselModule) },
        { path: 'green',        loadChildren: () => import('./versions/v2/roster/green').then(m => m.GreenModule) },
        { path: 'korantzopoulos', loadChildren: () => import('./versions/v2/roster/korantzopoulos').then(m => m.KorantzopoulosModule) },
        { path: 'pekrul',       loadChildren: () => import('./versions/v2/roster/pekrul').then(m => m.PekrulModule) },
        { path: 'goldschlager', loadChildren: () => import('./versions/v2/roster/goldschlager').then(m => m.GoldschlagerModule) },
        { path: 'quiroga', loadChildren: () => import('./versions/v2/roster/quiroga').then(m => m.QuirogaModule) },
      ]
  },
];

const routesCasesV1: Routes = [
  { path:'v1/case', component: CaseComponent,
      children: [
        // Grupo 1
        { path: 'scheinman2',  loadChildren: () => import('./versions/v1/roster/scheinman2').then(m => m.Scheinman2Module) },
        { path: 'dubner',  loadChildren: () => import('./versions/v1/roster/dubner').then(m => m.DubnerModule) },
        { path: 'stone',  loadChildren: () => import('./versions/v1/roster/stone').then(m => m.StoneModule) },
        { path: 'mcintyre',  loadChildren: () => import('./versions/v1/roster/mcintyre').then(m => m.McintyreModule) },
        // Grupo 2
        { path: 'needleman',  loadChildren: () => import('./versions/v1/roster/needleman').then(m => m.NeedlemanModule) },
        { path: 'varma',      loadChildren: () => import('./versions/v1/roster/varma').then(m => m.VarmaModule) },
        { path: 'baranchuk',  loadChildren: () => import('./versions/v1/roster/baranchuk').then(m => m.BaranchukModule) },
        { path: 'platonov',  loadChildren: () => import('./versions/v1/roster/platonov').then(m => m.PlatonovModule) },
        { path: 'chevalier',  loadChildren: () => import('./versions/v1/roster/chevalier').then(m => m.ChevalierModule) },
        // Grupo 3
        { path: 'bayes',  loadChildren: () => import('./versions/v1/roster/bayes').then(m => m.BayesModule) },
        { path: 'fiol',  loadChildren: () => import('./versions/v1/roster/fiol').then(m => m.FiolModule) },
        // Grupo 4
        { path: 'martingreen',  loadChildren: () => import('./versions/v1/roster/martingreen').then(m => m.MartinGreenModule) },
        { path: 'ikeda',  loadChildren: () => import('./versions/v1/roster/ikeda').then(m => m.IkedaModule) },
        { path: 'locati',  loadChildren: () => import('./versions/v1/roster/locati').then(m => m.LocatiModule) },
        { path: 'scheinman1',  loadChildren: () => import('./versions/v1/roster/scheinman1').then(m => m.Scheinman1Module) },
        // Grupo 5
        { path: 'moss',  loadChildren: () => import('./versions/v1/roster/moss').then(m => m.MossModule) },
        { path: 'tse',  loadChildren: () => import('./versions/v1/roster/tse').then(m => m.TseModule) },
        { path: 'lopez',  loadChildren: () => import('./versions/v1/roster/lopez').then(m => m.LopezModule) },
        // Grupo 6
        { path: 'nikus',  loadChildren: () => import('./versions/v1/roster/nikus').then(m => m.NikusModule) },
        { path: 'bacharova',  loadChildren: () => import('./versions/v1/roster/bacharova').then(m => m.BacharovaModule) },
        { path: 'letsas',  loadChildren: () => import('./versions/v1/roster/letsas').then(m => m.LetsasModule) },
        // Grupo 7
        { path: 'scheinman3',  loadChildren: () => import('./versions/v1/roster/scheinman3').then(m => m.Scheinman3Module) },
        { path: 'marek',  loadChildren: () => import('./versions/v1/roster/marek').then(m => m.MarekModule) },
        // Grupo 8
        { path: 'larisa',  loadChildren: () => import('./versions/v1/roster/larisa').then(m => m.LarisaModule) },
        { path: 'shenasa',  loadChildren: () => import('./versions/v1/roster/shenasa').then(m => m.ShenasaModule) },
        
      ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routesCasesV1),
    RouterModule.forRoot(routesCasesV2),
    RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', anchorScrolling: 'enabled'})
  ],
  exports: [RouterModule]
})



export class AppRoutingModule { }
