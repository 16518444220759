import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
//import { FirebaseService } from 'src/app/shared/services/firebase.service';
//import { DomSanitizer } from '@angular/platform-browser';
import { ArrayType } from '@angular/compiler';
//import { EcgChartComponent } from '../ecgchart/ecgchart.component';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
  // para que innerHtml use el estilo del componente
  encapsulation: ViewEncapsulation.None
})

export class CaseComponent implements OnInit {

  isLoaded = true;
  // name: any;
  public title = '';
  public subtitle = '';
  public authors: ArrayType;
  public med_titles: ArrayType;
  public avatar: ArrayType;
  public countries: ArrayType;
  public author_link: ArrayType;
  public bib: ArrayType;
  public myTemplate: any = '';

  constructor(private route: ActivatedRoute, private firebaseService: FirebaseService, private analytics: AngularFireAnalytics) {
    
    const currCase = this.route.snapshot.children[0].children[0].data.case;

    let version: string = '';
    if (currCase != undefined) {
      version = currCase.num.substring(0, currCase.num.indexOf("."));
      this.title = 'Case #' + currCase.num;
      this.authors = currCase.authors;
      this.med_titles = currCase.titles;
      this.avatar = currCase.avatar;
      this.countries = currCase.country;
      this.author_link = currCase.link;
      this.bib = currCase.bib;
      this.subtitle = currCase.authors.join(" and ");
    } else {
      this.title = 'Case not found';
    }

    if (version != '') {
      analytics.logEvent('case_v' + version, {num: currCase.num});
      firebaseService.logVisit('case v' + version);
      firebaseService.logVisit('case_v' + currCase.num);
    }
  }

  ngOnInit() {

    // route.url.subscribe(() => {
      //const version = this.router.url.split('/')[1];
      // const name = this.router.url.split('/')[3];
      //const currCase = this.route.snapshot.data['cases'][name];
      // const cases = this.route.snapshot.data['cases'];
      // const currCase = cases.find( (c: any) => c.id === name );
      // const currCase = this.route.snapshot.firstChild.data['case'];
      // const currCase = this.route.snapshot.data['case'];
    // }

    // Get parent ActivatedRoute of this route.
    /*const version = this.router.url.split('/')[1];
    this.name = this.router.url.split('/')[3];
    this.firebaseService.getCaseV2(version, this.name).subscribe(
      ref => {
        if (ref != undefined) {
          //var template = $templateCache.get('nameOfTemplate.html');
          // bypassSecurityTrustHtml permite que se puedan cargar las imagenes
          // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(EcgChartComponent);
          // const viewContainerRef = this.adHost.viewContainerRef;

          // this.myTemplate =  testhtml;
          // this.myTemplate =  this.sanitizer.bypassSecurityTrustHtml(testhtml); //this.sanitizer.bypassSecurityTrustHtml("<ecgchart [ecgX]=179 [ecgY]=120 [pixelX]=960 [pixelY]=637 [backgroundImage]='/assets/cases/case_chevalier_01.jpg'></ecgchart>");// this.sanitizer.bypassSecurityTrustHtml(testhtml); // this.sanitizer.bypassSecurityTrustHtml(ref['html']);//
          // this.myTemplate =  "<ecgchart [ecgX]=179 [ecgY]=120 [pixelX]=960 [pixelY]=637 [backgroundImage]='/assets/cases/case_chevalier_01.jpg'></ecgchart>";
          this.title = 'Case #' + ref['num'];
          this.authors = ref['authors'];
          this.avatar = ref['avatar'];
          this.countries = ref['country'];
          this.author_link = ref['author_link'];
          this.bib = ref['bib'];
          this.subtitle = ref['authors'].join(" and ");
        } else {
          this.title = 'Case not found';
        }
        this.isLoaded = true;
      }
    );*/
  }

}
