<div id="share-buttons">
    <div class="table-container">
        <div id="share-button">
            <!-- Facebook -->
            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fecguniversity.org%2F&amp;src=sdkpreparse" target="_blank"  class="fb-xfbml-parse-ignore">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-facebook-2" class="at-icon at-icon-facebook" style="fill: rgb(102, 102, 102); width: 32px; height: 32px;"><g><path d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z" fill-rule="evenodd"></path></g></svg>
            </a>
        </div>
        <div id="share-button">
            <!-- LinkedIn -->
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://ecguniversity.org&title=ECG&nbsp;University&nbsp;v2&summary=In this new version, we focus on cardiac implantable electronic devices and the associated ECGs, including a variety of clinically relevant and interesting cases.&source=" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-linkedin-4" class="at-icon at-icon-linkedin" style="fill: rgb(102, 102, 102); width: 32px; height: 32px;"><g><path d="M26 25.963h-4.185v-6.55c0-1.56-.027-3.57-2.175-3.57-2.18 0-2.51 1.7-2.51 3.46v6.66h-4.182V12.495h4.012v1.84h.058c.558-1.058 1.924-2.174 3.96-2.174 4.24 0 5.022 2.79 5.022 6.417v7.386zM8.23 10.655a2.426 2.426 0 0 1 0-4.855 2.427 2.427 0 0 1 0 4.855zm-2.098 1.84h4.19v13.468h-4.19V12.495z" fill-rule="evenodd"></path></g></svg>
            </a>
        </div>
        <div id="share-button">
            <a href="https://twitter.com/intent/tweet?button_hashtag=ecguniversity&text=In this new version, we focus on cardiac implantable electronic devices and the associated ECGs, including a variety of clinically relevant and interesting cases. https://ecguniversity.org" target="_blank" class="twitter-share-button">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-twitter-1" class="at-icon at-icon-twitter" style="fill: rgb(102, 102, 102); width: 32px; height: 32px;"><g><path d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336" fill-rule="evenodd"></path></g></svg>
            </a>
        </div>
		<div id="share-button">
             <!-- Email -->
            <a href="mailto:?Subject=ECG University v2&amp;Body=In this new version, we focus on cardiac implantable electronic devices and the associated ECGs, including a variety of clinically relevant and interesting cases. https://ecguniversity.org">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<g><path d="M908,167.8H92c-45.2,0-82,36.8-82,82.1v500.4c0,45.3,36.8,82,82,82h816c45.4,0,82-36.7,82-82V249.8C990,204.5,953.4,167.8,908,167.8L908,167.8L908,167.8z M866.8,237.9L559.7,510.1c-26.8,23.9-46.7,35.7-59.4,35.7c-12.8,0-32.6-11.8-59.4-35.7L133.8,237.9H866.8L866.8,237.9L866.8,237.9z M81.4,723.5V289.9l243.8,213.8L81.4,723.5L81.4,723.5L81.4,723.5z M141.3,761.3l237.1-210.6l38.9,34.1c22.6,19.7,50.3,29.8,83.1,29.8c32.9,0,60.5-10.1,83.2-29.8l38.9-34.1l237.1,210.6H141.3L141.3,761.3L141.3,761.3z M919.2,723.5L675.4,503.7l243.8-213.8V723.5L919.2,723.5L919.2,723.5z"/></g>
</svg>
            </a>
        </div>
    </div>
</div>

